import { Chip, Typography } from '@mui/material'

export interface UserTitleProps {
    title?: string
    black?: boolean
}

export const UserTitle = (props: UserTitleProps) => {
    const { title, black } = props
    if (!title) {
        return null
    }
    return (
        <Chip
            className={`userTitle ${black && 'black'}`}
            label={
                <Typography
                    sx={{
                        fontFamily: 'Space Grotesk',
                        fontWeight: 700,
                        fontSize: '0.75rem',
                        textTransform: 'uppercase',
                    }}
                >
                    {title}
                </Typography>
            }
        />
    )
}
