import Close from '@mui/icons-material/Close'
import { Box, Dialog, DialogActions, DialogContent, IconButton, Typography } from '@mui/material'
import { ReactNode } from 'react'

export const InfoDialogText = ({
    header,
    subheader,
    description,
}: {
    header: ReactNode
    subheader: ReactNode
    description: ReactNode
}) => {
    return (
        <>
            <Typography variant="h1" sx={{ width: '40px' }} color="secondary" paragraph>
                {header}
            </Typography>
            <Typography variant="h2" paragraph>
                {subheader}
            </Typography>
            <Typography variant="body2">{description}</Typography>
        </>
    )
}

type InfoDialogTemplateProps = {
    open: boolean
    handleClose: () => void
    content: ReactNode
    actions: ReactNode
}

export const InfoDialogTemplate: React.FC<InfoDialogTemplateProps> = ({ handleClose, content, actions, open }) => {
    return (
        <Dialog
            sx={{
                '.MuiPaper-root': {
                    backgroundColor: 'background.default',
                    maxWidth: '600px',
                    width: '100%',
                    flexDirection: 'column',
                    zIndex: 1600,
                    p: { xs: '16px', sm: '40px' },
                },
            }}
            open={open}
            onClose={handleClose}
            scroll="paper"
        >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                <IconButton size="small" sx={{ svg: { fontSize: '34px' } }} onClick={handleClose} disableRipple>
                    <Close />
                </IconButton>
            </Box>
            <DialogContent
                sx={{
                    p: 0,
                    px: { xs: 0, md: 2 },
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    br: {
                        display: 'block',
                        content: '""',
                        marginTop: 2,
                    },
                }}
                dividers={false}
            >
                <Box
                    sx={{
                        maxWidth: 'min(375px, 100%)',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        my: { xs: 2, md: 4 },
                    }}
                >
                    {content}
                </Box>
            </DialogContent>
            <DialogActions
                disableSpacing
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    gap: 2,
                    button: { maxWidth: '260px' },
                }}
            >
                {actions}
            </DialogActions>
        </Dialog>
    )
}
