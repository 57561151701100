import { useCallback, useEffect, useMemo, useState } from 'react'
import CorrectIcon from '../../assets/icons/correct.svg?react'
import IncorrectIcon from '../../assets/icons/incorrect.svg?react'
import { nameOfSquare, pieceSymbol, squareByName } from '../../chess/basics'
import { generateMoves } from '../../chess/core'
import { positionToFEN } from '../../chess/fen'
import { getLastPosition, getMoveById, getPositionById } from '../../chess/gameTree'
import { Color, GameTree, Move, MoveType } from '../../chess/types'
import { Chessboard } from '../../react-chessboard/src'
import { Piece as LibPiece, Square } from '../../react-chessboard/src/chessboard/types'
import { piecesProvider } from '../../sharedComponents/src/globalHeader/common/PiecesProvider'
import { boardColors } from '../../sharedComponents/src/globalHeader/theme/colors'
import { useStoreState } from '../../store/hooks'
import useMoveSounds from './hooks/useMoveSounds'
import {
    combineStyles,
    getCheckHighlightStyles,
    getHintStyles,
    getLastMoveHighlightsStyles,
    getRightClickedSquareStyles,
    getSelectedSquareStyles,
    libPieceToEnumPieceMap,
} from './utils'

export type ChessBoardProps = {
    gameTree: GameTree
    currentPositionId: string
    flipped: boolean
    onMove: (mov: Move) => boolean
    myColor: Color
    hint?: Square
    disableBoard?: boolean
    moveStatus?: boolean
}

const PuzzlesChessBoard = (props: ChessBoardProps) => {
    let { gameTree, currentPositionId, flipped, onMove, myColor, hint, disableBoard = false, moveStatus } = props

    const settings = useStoreState((state) => state.gameView.settings)
    const [rightClickedSquares, setRightClickedSquares] = useState<Square[]>([])
    const [moveFrom, setMoveFrom] = useState<Square | undefined>(undefined)
    const [moveTo, setMoveTo] = useState<Square | undefined>(undefined)
    const [promotionReason, setPromotionReason] = useState<'move' | 'premove' | undefined>(undefined)
    const [dragPiece, setDragPiece] = useState<LibPiece | undefined>(undefined)

    useEffect(() => {
        setMoveFrom(undefined)
        setDragPiece(undefined)
    }, [gameTree])

    const position = useMemo(() => {
        return getPositionById(gameTree, currentPositionId).position
    }, [gameTree, currentPositionId])

    const isLastPosition = useMemo(() => {
        return currentPositionId === getLastPosition(gameTree).id
    }, [gameTree, currentPositionId])

    const playMoveSound = useMoveSounds(settings)

    const makeMoveHandler = useCallback(
        (move: Move) => {
            if (!isLastPosition) return

            onMove(move)
            if (moveFrom === nameOfSquare(move.from)) setMoveFrom(undefined)
            setMoveTo(undefined)
        },
        [onMove, isLastPosition],
    )

    const activeMove = useMemo(() => {
        const tp = getPositionById(gameTree, currentPositionId)

        if (tp.previousMoveId !== undefined) {
            return getMoveById(gameTree, tp.previousMoveId)
        }
    }, [gameTree, currentPositionId])

    useEffect(() => {
        playMoveSound(activeMove?.displayString)
    }, [activeMove])

    const pieceDragBeginHandler = useCallback((piece: LibPiece, sourceSquare: Square) => {
        setPromotionReason(undefined)
        setDragPiece(piece)
        setMoveFrom(sourceSquare)
    }, [])

    const onFirstClick = useCallback((sourceSquare: Square) => {
        setPromotionReason(undefined)
        setRightClickedSquares([])
        setMoveFrom(sourceSquare)
    }, [])

    const onSecondClick = useCallback(
        (moveFrom: Square, sourceSquare: Square) => {
            const moves = generateMoves(position, squareByName(moveFrom))
            const legalMove = moves.find((m) => nameOfSquare(m.to) === sourceSquare)
            if (legalMove) {
                if (legalMove.promotion) {
                    setMoveTo(sourceSquare)
                    setPromotionReason('move')
                    return
                } else {
                    makeMoveHandler(legalMove)
                }
            } else {
                onFirstClick(sourceSquare)
            }
        },
        [position, makeMoveHandler],
    )

    const onClickHandler = useCallback(
        (sourceSquare: Square) => {
            if (!moveFrom) {
                onFirstClick(sourceSquare)
                return
            }

            onSecondClick(moveFrom, sourceSquare)
        },
        [onFirstClick, onSecondClick, moveFrom],
    )

    const pieceDropHandler = useCallback(
        (sourceSquare: Square, targetSquare: Square, piece: LibPiece) => {
            setRightClickedSquares([])

            const moves = generateMoves(position, squareByName(sourceSquare))
            const legalMove = moves.find((m) => nameOfSquare(m.to) === targetSquare)

            if (legalMove) {
                if (legalMove.promotion) {
                    setMoveTo(targetSquare)
                    setMoveFrom(sourceSquare)
                    setPromotionReason('move')
                    return false
                } else {
                    makeMoveHandler(legalMove)
                    return true
                }
            }

            return false
        },
        [dragPiece, makeMoveHandler, position],
    )

    const squareRightClickHandler = useCallback((sourceSquare) => {
        setRightClickedSquares((prevState) => {
            if (prevState.some((square) => square === sourceSquare)) {
                return prevState.filter((square) => square !== sourceSquare)
            } else {
                return [...prevState, sourceSquare]
            }
        })
    }, [])

    const boardColor = boardColors[settings.boardStyle] || boardColors.default

    const customSquareStyles = useMemo(() => {
        return combineStyles(
            getLastMoveHighlightsStyles(
                activeMove?.move,
                hint === activeMove?.move.from,
                myColor !== position.turn,
                moveStatus,
            ),
            getCheckHighlightStyles(position),
            getRightClickedSquareStyles(rightClickedSquares),
            getSelectedSquareStyles(position, moveFrom, [], position.turn, hint === moveFrom),
            getHintStyles(hint),
        )
    }, [activeMove, position, rightClickedSquares, moveFrom, hint])

    const isDraggablePiece = useCallback(
        ({ piece }) => {
            if (!disableBoard) {
                return myColor === Color.White ? piece.startsWith('w') : piece.startsWith('b')
            }
        },
        [myColor, disableBoard],
    )

    const lastMovedPiece = useMemo(() => {
        if (!activeMove) return ''
        return pieceSymbol(position, activeMove?.move.to)
    }, [position, activeMove])

    const lastMovedSquare = useMemo(() => {
        if (!activeMove) return ''
        return nameOfSquare(activeMove?.move.to)
    }, [activeMove])

    const pieces = useMemo(() => {
        return piecesProvider(settings.pieceStyle)
    }, [settings.pieceStyle])

    return (
        <Chessboard
            animationDuration={200}
            promotionDialogVariant={'modal'}
            customSquareStyles={customSquareStyles}
            position={positionToFEN(position)}
            customDarkSquareStyle={boardColor.secondary}
            customLightSquareStyle={boardColor.main}
            showBoardNotation={settings.coordinates}
            boardOrientation={flipped ? 'black' : 'white'}
            isDraggablePiece={isDraggablePiece}
            onPieceDragBegin={pieceDragBeginHandler}
            onSquareClick={onClickHandler}
            onSquareRightClick={squareRightClickHandler}
            onPieceDrop={pieceDropHandler}
            customPieces={{
                ...pieces,
                [lastMovedPiece]: ({ isDragging, squareWidth, square }) =>
                    square === lastMovedSquare && myColor !== position.turn ? (
                        <>
                            {moveStatus === false ? (
                                <IncorrectIcon
                                    style={{
                                        position: 'fixed',
                                        top: '-0.3rem',
                                        right: '-0.25rem',
                                        width: `${squareWidth / 3.5}px`,
                                        height: `${squareWidth / 3.5}px`,
                                    }}
                                />
                            ) : (
                                <CorrectIcon
                                    style={{
                                        position: 'fixed',
                                        top: '-0.3rem',
                                        right: '-0.25rem',
                                        width: `${squareWidth / 3.5}px`,
                                        height: `${squareWidth / 3.5}px`,
                                    }}
                                />
                            )}
                            {pieces[lastMovedPiece]({ isDragging, squareWidth })}
                        </>
                    ) : (
                        pieces[lastMovedPiece]({ isDragging, squareWidth })
                    ),
            }}
            customDropSquareStyle={{}}
            areArrowsAllowed={true}
            showPromotionDialog={false}
            onPromotionCheck={(piece) => promotionReason !== undefined}
            promotionToSquare={undefined}
            {...(promotionReason !== undefined && {
                showPromotionDialog: true,
                promotionToSquare: moveTo as unknown as Square,
                onPromotionPieceSelect: (piece) => {
                    if (piece) {
                        const promotionPiece = libPieceToEnumPieceMap[piece]
                        const move = {
                            from: squareByName(moveFrom!),
                            to: squareByName(moveTo!),
                            type: MoveType.Promotion,
                            promotion: promotionPiece,
                        }

                        makeMoveHandler(move)
                        setPromotionReason(undefined)
                        return true
                    }
                    setPromotionReason(undefined)
                    return false
                },
            })}
        />
    )
}

export default PuzzlesChessBoard
