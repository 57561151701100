import BackIcon from '@mui/icons-material/ArrowBackIos'
import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton, Palette, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MainMenu } from '../../components/MainMenu'
import { AlreadyConnectedDialog } from '../../components/overlays/AlreadyConnectedDialog/AlreadyConnectedDialog'
import { TabBar } from '../../components/tabBar/TabBar'
import { UserSearchResult } from '../../sharedComponents/src/globalHeader/functions/ams-functions'
import { useStoreActions, useStoreState } from '../../store/hooks'
import { GameViewState } from '../gameView/GameViewModel'
import { LoadingBox } from '../gameView/components/LoadingBox'
import CustomChallengeDrawer from './CustomChallengeDrawer'
import DirectChallengeDrawer from './DirectChallengeDrawer'
import PlayAnyonePanel from './playAnyonePanel/PlayAnyonePanel'
import PlayComputerPanel from './playComputerPanel/PlayComputerPanel'
import PlayFriendsPanel from './playFriendsPanel/PlayFriendsPanel'

const MMDrawerContainerStyles = (palette: Palette) => ({
    position: 'relative',
    border: '0.25rem solid white',
    backgroundImage: 'none',
    outline: 0,
    overflowY: 'auto',
    overflowX: 'hidden',
    boxShadow: 'none',
    padding: '16px',
    paddingTop: '24px',
    display: 'flex',
    flexFlow: 'column',
    backgroundColor: palette.background.default,
    gap: '8px',
    flexGrow: 1,
})

function MatchMakingDrawer() {
    const activeTab = useStoreState((state) => state.activeTab)
    const alreadyConnected = useStoreState((state) => state.matchMaker.alreadyConnected)
    const drawerOpen = useStoreState((state) => state.drawerOpen)
    const gameInQueue = useStoreState((state) => state.matchMaker.gameInQueue)
    const isDrawerMenuOpen = useStoreState((state) => state.isDrawerMenuOpen)
    const userData = useStoreState((state) => state.userData)
    const gameState = useStoreState((state) => state.gameView.gameState)

    const setActiveTab = useStoreActions((state) => state.setActiveTab)
    const setAlreadyConnected = useStoreActions((state) => state.matchMaker.setAlreadyConnected)
    // const setGameToQueue = useStoreActions((state) => state.matchMaker.setGameToQueue)
    const setDrawerOpen = useStoreActions((state) => state.setDrawerOpen)
    const setIsDrawerMenuOpen = useStoreActions((state) => state.setIsDrawerMenuOpen)
    const getGameFromQueue = useStoreActions((state) => state.matchMaker.getGameFromQueue)

    const navigate = useNavigate()

    const [customChallengePanelOpen, setCustomChallengePanelOpen] = useState<boolean>(false)
    const [directChallengePanelOpen, setDirectChallengePanelOpen] = useState<boolean>(false)
    const [challengee, setChallengee] = useState<UserSearchResult | undefined>()

    const { palette } = useTheme()

    const handleCustomChallengePanel = () => {
        setCustomChallengePanelOpen(!customChallengePanelOpen)
    }

    const handleDirectChallengePanel = () => {
        setDirectChallengePanelOpen(!directChallengePanelOpen)
    }

    useEffect(() => {
        const isPlayZone = window.location.pathname === '/'
        if (isPlayZone) {
            setDrawerOpen('open')
            setIsDrawerMenuOpen(false)
        } else {
            setDrawerOpen('closed')
            setIsDrawerMenuOpen(false)
        }
    }, [window.location.pathname])

    const handleMenuNavigate = (to: string) => {
        navigate(to)
        setDrawerOpen('closed')
        setIsDrawerMenuOpen(false)
    }

    const isPlayZone = window.location.pathname === '/'
    if (drawerOpen === 'loading') return <LoadingBox dialog={false} />
    return (
        <>
            {drawerOpen === 'open' && (
                <>
                    {alreadyConnected || gameInQueue || isDrawerMenuOpen || gameState === GameViewState.GAME_RUNNING ? (
                        <MainMenu
                            onPlayClick={() => {
                                setDrawerOpen('open')
                                setIsDrawerMenuOpen(false)
                                // alreadyConnected && setAlreadyConnected(false)
                                navigate('/')
                            }}
                            onAnalysisClick={() => {
                                // if (!gameTree || !currentPositionId) {
                                //     navigate('/analysis')
                                // } else {
                                //     setAnalysisGameTree(gameTree)
                                handleMenuNavigate('/analysis')
                                // }
                            }}
                            onWatchClick={() => {
                                handleMenuNavigate('/watch')
                            }}
                            dialog={false}
                        />
                    ) : (
                        <Box sx={MMDrawerContainerStyles(palette)}>
                            {customChallengePanelOpen && <CustomChallengeDrawer onClose={handleCustomChallengePanel} />}
                            {!!challengee && (
                                <DirectChallengeDrawer
                                    onClose={() => setChallengee(undefined)}
                                    user={challengee}
                                    setActiveMainTab={setActiveTab}
                                />
                            )}
                            {!customChallengePanelOpen && !challengee && (
                                <>
                                    {!isPlayZone && (
                                        <IconButton
                                            onClick={() => setDrawerOpen('closed')}
                                            sx={{ position: 'absolute', right: 8, top: 0, zIndex: 2 }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    )}
                                    <IconButton
                                        onClick={() => setIsDrawerMenuOpen(true)}
                                        size="small"
                                        sx={{
                                            width: 'auto',
                                            position: 'absolute',
                                            left: 12,
                                            top: 8,
                                            zIndex: 2,
                                            fontSize: '18px !important',
                                            ':hover': {
                                                background: 'unset',
                                            },
                                        }}
                                    >
                                        <BackIcon fontSize="small" /> Menu
                                    </IconButton>
                                    <Box style={{ marginBottom: '16px', marginTop: '24px' }}>
                                        <TabBar
                                            onTabClick={(tabIndex: number) => {
                                                setActiveTab(tabIndex)
                                            }}
                                            tabLabels={[
                                                'New game',
                                                <>
                                                    Players
                                                    {/* {onlinePresence && (
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: '-0.875rem',
                                                right: '-0.625rem',
                                                textTransform: 'initial',
                                            }}
                                        >
                                            <OnlinePresenceIndicator usersOnline={onlinePresence} />
                                        </div>
                                    )} */}
                                                </>,
                                                'Computer',
                                            ]}
                                            initSelected={activeTab}
                                        />
                                    </Box>
                                    {activeTab === 0 && !customChallengePanelOpen && (
                                        <PlayAnyonePanel
                                            loggedIn={userData.state === 'logged_in'}
                                            onCustomChallengeClick={handleCustomChallengePanel}
                                            userData={userData}
                                        />
                                    )}
                                    {activeTab === 1 && !customChallengePanelOpen && (
                                        <PlayFriendsPanel
                                            setChallengee={setChallengee}
                                            loggedIn={userData.state === 'logged_in'}
                                            onClose={handleDirectChallengePanel}
                                        />
                                    )}
                                    {activeTab === 2 && !customChallengePanelOpen && (
                                        <PlayComputerPanel loggedIn={userData.state === 'logged_in'} />
                                    )}
                                </>
                            )}
                        </Box>
                    )}
                </>
            )}
            {drawerOpen === 'open' && !isDrawerMenuOpen && (
                <AlreadyConnectedDialog
                    isOpen={alreadyConnected || gameInQueue}
                    onClose={() => {
                        setIsDrawerMenuOpen(true)
                        !isPlayZone && setDrawerOpen('closed')
                    }}
                    onSubmit={async () => {
                        getGameFromQueue()
                        setAlreadyConnected(false)
                        setIsDrawerMenuOpen(false)
                        navigate('/')
                        // setGameToQueue(false)
                    }}
                />
            )}
            {/* <GameInProgressOverlay
                isOpen={showDialogs && gameInQueue}
                onClose={() => {
                    setShowDialogs(false)
                    !isPlayZone ? setDrawerOpen('closed') : setIsDrawerMenuOpen(true)
                }}
                onSubmit={() => {
                    getGameFromQueue()
                    setShowDialogs(false)
                    setIsDrawerMenuOpen(false)
                    navigate('/')
                }}
            /> */}
        </>
    )
}

export default MatchMakingDrawer
