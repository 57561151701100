import { GameData, LiveGameData, RatingsObject } from '../store/types'

export type PlayerInfo = {
    name: string
    avatarUrl?: string
    nationality?: string
    rating?: number
    title?: string
    userName?: string
    human?: boolean
    playerId?: string
    ratings?: RatingsObject | null
    ratingType?: string
    showScore?: boolean
}

export const getOpponentInfos = (
    md: LiveGameData | undefined,
): { playerWhite: PlayerInfo; playerBlack: PlayerInfo } => {
    const playerWhite: PlayerInfo = { name: '' }
    const playerBlack: PlayerInfo = { name: '' }

    if (!!md) {
        const whiteUser = md.player1.playerColor === 'white' ? md.player1 : md.player2
        const blackUser = md.player1.playerColor === 'black' ? md.player1 : md.player2

        playerWhite.rating = whiteUser.ratings ? whiteUser.ratings[md.timeMode.name.toLowerCase()].rating : null
        playerWhite.nationality = whiteUser.countryId
        playerWhite.playerId = whiteUser.playerId
        playerWhite.name = whiteUser.userName
        playerWhite.avatarUrl = whiteUser.avatarUrl
        playerWhite.ratings = whiteUser.ratings
        playerWhite.ratingType = md.timeMode.name
        playerWhite.showScore = false
        playerWhite.title = whiteUser.title

        playerBlack.rating = blackUser.ratings ? blackUser.ratings[md.timeMode.name.toLowerCase()].rating : null
        playerBlack.nationality = blackUser.countryId
        playerBlack.playerId = blackUser.playerId
        playerBlack.name = blackUser.userName
        playerBlack.avatarUrl = blackUser.avatarUrl
        playerBlack.ratings = blackUser.ratings
        playerBlack.ratingType = md.timeMode.name
        playerBlack.showScore = false
        playerBlack.title = blackUser.title
    }

    return { playerWhite, playerBlack }
}

export const getPlayerInfos = (md: GameData | undefined): { playerWhite: PlayerInfo; playerBlack: PlayerInfo } => {
    const playerWhite: PlayerInfo = { name: '' }
    const playerBlack: PlayerInfo = { name: '' }

    if (!!md) {
        const whiteUser = md.player1.playerColor === 'white' ? md.player1 : md.player2
        const blackUser = md.player1.playerColor === 'black' ? md.player1 : md.player2

        playerWhite.name = whiteUser.userName
        playerBlack.name = blackUser.userName

        if (whiteUser.playerKind === 'human') {
            playerWhite.avatarUrl = whiteUser.avatarUrl
            if (playerWhite.avatarUrl === '') playerWhite.avatarUrl = undefined
            playerWhite.human = true
        } else playerWhite.avatarUrl = '/aiavatar.webp'

        if (blackUser.playerKind === 'human') {
            playerBlack.avatarUrl = blackUser.avatarUrl
            if (playerBlack.avatarUrl === '') playerBlack.avatarUrl = undefined
            playerBlack.human = true
        } else playerBlack.avatarUrl = '/aiavatar.webp'

        if (whiteUser.playerKind === 'machine') {
            playerWhite.name = 'Stockfish'
            playerWhite.human = false
        }
        if (blackUser.playerKind === 'machine') {
            playerBlack.name = 'Stockfish'
            playerBlack.human = false
        }

        playerWhite.rating = whiteUser.ratings ? whiteUser.ratings[md.timeMode.name.toLowerCase()].rating : null
        playerBlack.rating = blackUser.ratings ? blackUser.ratings[md.timeMode.name.toLowerCase()].rating : null
        playerWhite.ratings = whiteUser.ratings
        playerBlack.ratings = blackUser.ratings
        playerWhite.ratingType = md.timeMode.name
        playerBlack.ratingType = md.timeMode.name
        playerWhite.showScore = whiteUser.playerKind === 'human' && blackUser.playerKind === 'human'
        playerBlack.showScore = whiteUser.playerKind === 'human' && blackUser.playerKind === 'human'

        if (whiteUser.playerKind === 'human') {
            playerWhite.nationality = whiteUser.countryId
            playerWhite.playerId = whiteUser.playerId
            playerWhite.title = whiteUser.title
        }
        if (blackUser.playerKind === 'human') {
            playerBlack.nationality = blackUser.countryId
            playerBlack.playerId = blackUser.playerId
            playerBlack.title = blackUser.title
        }
    }

    return { playerWhite, playerBlack }
}
