import { Paper } from '@mui/material'
import * as Sentry from '@sentry/react'
import { useContext, useEffect } from 'react'

import { useNavigate } from 'react-router-dom'
import { ServerConnectionStates } from '../../../../App'
import { Color } from '../../../../chess/types'
import { analyticsManager } from '../../../../sharedComponents/src/globalHeader/services/analytics/AnalyticsManager'
import ReconnectingWebSocket, {
    CloseEvent,
    ErrorEvent,
    Options,
    WS_ERROR_EVENT_STATUS,
} from '../../../../socket/ReconnectingWebsocket'
import { useStoreActions, useStoreState } from '../../../../store/hooks'
import { ConnectionStatus, GameData, GameResult, GameResultType } from '../../../../store/types'
import { GameViewState, StateChangeRequest } from '../../GameViewModel'
import { getGameResultType } from '../gameOverDialog/utils'
import { GS_MESSAGES, GS_REQUESTS } from './GSTypes'

const END_OF_GAME_DELAY = 500 // ms

const GameServerConnector: React.FC = () => {
    const serverConnectionStates = useContext(ServerConnectionStates)

    const serverConnectionData = useStoreState((state) => state.serverConnectionData)
    const authToken = useStoreState((state) => state.token)
    const userID = useStoreState((state) => state.userData.userData?.id)
    const userData = useStoreState((state) => state.userData.userData)
    const matchData = useStoreState((state) => state.gameView.matchData)
    const connectionStatus = useStoreState((state) => state.gameView.connectionStatus)
    const gameStartedTimestamp = useStoreState((state) => state.gameView.gameStartedTimestamp)
    const gameState = useStoreState((state) => state.gameView.gameState)

    const setSocket = useStoreActions((state) => state.gameView.setSocket)
    const receiveMove = useStoreActions((state) => state.gameView.receiveMove)
    const receiveClockUpdate = useStoreActions((state) => state.gameView.receiveClockUpdate)
    const initialiseGame = useStoreActions((state) => state.gameView.initialiseGame)
    const sendJoin = useStoreActions((state) => state.gameView.sendJoin)
    const setGameResult = useStoreActions((state) => state.gameView.setGameResult)
    const addMoveOpening = useStoreActions((state) => state.gameView.addMoveOpening)
    const setDrawerOpen = useStoreActions((state) => state.setDrawerOpen)
    const setIsDrawerMenuOpen = useStoreActions((state) => state.setIsDrawerMenuOpen)
    const setDrawStatus = useStoreActions((state) => state.gameView.setDrawStatus)
    const setAbortStatus = useStoreActions((state) => state.gameView.setAbortStatus)
    const setConnectionStatus = useStoreActions((state) => state.gameView.setConnectionStatus)
    const setGameState = useStoreActions((state) => state.gameView.setGameState)
    const resetGame = useStoreActions((state) => state.gameView.resetGame)
    const setChallenges = useStoreActions((state) => state.matchMaker.setChallenges)
    const getScore = useStoreActions((state) => state.matchMaker.getScore)
    const setGameEndOpen = useStoreActions((state) => state.gameView.setGameEndEnabled)
    const shutdownGSConnection = useStoreActions((state) => state.gameView.shutDownGSConnection)
    const receiveMoveRequestAcknowledgement = useStoreActions(
        (state) => state.gameView.receiveMoveRequestAcknowledgement,
    )
    const setOpponentDisconnected = useStoreActions((state) => state.gameView.setOpponentDisconnected)
    const setCanClaimVictory = useStoreActions((state) => state.gameView.setCanClaimVictory)
    const setMatchData = useStoreActions((state) => state.gameView.setMatchData)
    const initializeGameOnReconnect = useStoreActions((state) => state.gameView.initializeGameOnReconnect)
    const updateDeferredGameTree = useStoreActions((state) => state.gameView.updateDeferredGameTree)
    const navigate = useNavigate()

    useEffect(
        () => {
            if (serverConnectionStates.GSStatus.current) return

            console.log(
                `rendering gameServerConnector. conectionStatus: ${connectionStatus} matchData: ${JSON.stringify(
                    matchData,
                )}`,
            )
            if (
                authToken &&
                connectionStatus === ConnectionStatus.NOT_INITIALIZED &&
                matchData &&
                matchData.gameServiceUrl !== ''
            ) {
                //@ts-ignore
                serverConnectionStates.GSStatus.current = true

                const url = `${matchData.gameServiceUrl}/api/ws?token=${authToken}`
                const options: Options = {
                    debug: true,
                    debugName: 'GS',
                    maxReconnectionDelay: 5000,
                    minReconnectionDelay: 5000,
                    maxRetries: 3,
                }
                let ws = new ReconnectingWebSocket(url, undefined, options)
                ws.addEventListener('close', (ce: CloseEvent) => {})

                ws.addEventListener('open', () => {
                    setConnectionStatus(ConnectionStatus.CONNECTED)
                    setSocket(ws)
                    sendJoin()
                    console.log(`gameserver ws opened, sending join request`)
                })

                ws.addEventListener('reconnect', () => {
                    console.log('gameserver ws reconencted after it broke')
                    setConnectionStatus(ConnectionStatus.CONNECTED)
                    setSocket(ws)
                    sendJoin()
                })

                ws.addEventListener('error', (ev: ErrorEvent) => {
                    Sentry.captureMessage(`GameServer error: ${ev.message}`)
                    switch (ev.message) {
                        case WS_ERROR_EVENT_STATUS.TIMEOUT:
                            setConnectionStatus(ConnectionStatus.CONNECTING)
                            break
                        case WS_ERROR_EVENT_STATUS.MAX_RETRIES:
                            setConnectionStatus(ConnectionStatus.RECONNECT_FAILED)
                            break
                        default:
                            break
                    }
                })

                ws.addEventListener('message', (e) => {
                    const mData = JSON.parse(e.data)
                    if (mData.messageType === GS_MESSAGES.MOVE_AND_CLOCK && mData.data) {
                        // ----------------------------------------------------------- move update
                        if (mData.data.lastMove) {
                            const update = {
                                moveStr: mData.data.lastMove,
                                clock: mData.data.clock,
                                opening: mData.data.opening,
                                receivedAt: Date.now(),
                            }
                            receiveMove(update)
                        }
                    } else if (
                        (mData.messageType === GS_MESSAGES.CLOCK_UPDATED ||
                            mData.messageType === GS_MESSAGES.MATCH_STARTED) &&
                        mData.data
                    ) {
                        // ----------------------------------------------------------- clock starts or update
                        if (mData.data.whiteLeft) {
                            const update = {
                                clockBlack: mData.data.blackLeft,
                                clockWhite: mData.data.whiteLeft,
                            }
                            receiveClockUpdate(update)
                        }

                        setDrawerOpen('closed')
                        if (gameState !== GameViewState.GAME_RUNNING) {
                            setGameState(GameViewState.GAME_RUNNING)
                        }
                    } else if (mData.messageType === GS_MESSAGES.END_OF_GAME && mData.data && mData.data.method) {
                        // ----------------------------------------------------------- end of game
                        shutdownGSConnection()
                        setOpponentDisconnected(undefined)
                        setDrawerOpen('closed')
                        setTimeout(() => {
                            setGameEndOpen(true)
                        }, END_OF_GAME_DELAY)
                        setCanClaimVictory(false)

                        const d = mData.data
                        const result: GameResult = {
                            winner: undefined,
                            type: GameResultType.Unknown,
                            newRating: undefined,
                            clock: d.clock,
                            outcome: d.outcome,
                        }

                        if (d.ratings) result.newRating = d.ratings

                        // check the result who won ("0-1" is a fixed notation in chess!)
                        if (d.outcome === '1-0') result.winner = Color.White
                        else if (d.outcome === '0-1') result.winner = Color.Black

                        // decipher the reason
                        result.type = getGameResultType(d.reason)

                        setGameResult(result)
                        setMatchData({
                            matchId: '',
                            gameServiceUrl: '',
                        })

                        const timeInGame = gameStartedTimestamp ? Date.now() - gameStartedTimestamp : 0

                        analyticsManager.dispatchEvent('gameFinished', {
                            timeInGame: timeInGame / (60 * 1000),
                            reason: d.reason,
                            method: d.method,
                        })
                    } else if (mData.messageType === GS_MESSAGES.OPPONENT_DISCONNECT) {
                        // ----------------------------------------------------------- opponent disconnected
                        if (mData.data.secondsTillVictoryClaim)
                            setOpponentDisconnected({
                                timestamp: Date.now(),
                                secondsTillVictoryClaim: mData.data.secondsTillVictoryClaim,
                            })
                    } else if (mData.messageType === GS_MESSAGES.OPPONENT_CONNECTED) {
                        // ----------------------------------------------------------- opponent reconnected
                        setOpponentDisconnected(undefined)
                        setCanClaimVictory(false)
                        setDrawerOpen('closed')
                    } // ----------------------------------------------------------- can claim victory
                    else if (mData.messageType === GS_MESSAGES.MATCH_IS_VALID_FOR_CLAIM) {
                        setCanClaimVictory(true)
                    } else if (mData.requestType === GS_REQUESTS.MATCH_CLAIM_VICTORY) {
                        setCanClaimVictory(false)
                    } else if (mData.requestType === GS_REQUESTS.MATCH_WAIT_FOR_OPPONENT) {
                        setCanClaimVictory(false)
                    } // ----------------------------------------------------------- join response
                    else if (mData.requestType === GS_REQUESTS.JOIN && mData.data && mData.data.matchKind && userID) {
                        // technically 1077 means the match is not found but so far this is the consequence of a timeout
                        if (mData.result.errorCode === 1076 || mData.result.errorCode === 1077) {
                            setGameResult({
                                type: GameResultType.Timeout,
                                winner: undefined,
                            })

                            shutdownGSConnection()
                        }

                        const gd: GameData = mData.data

                        navigate('/')
                        setGameEndOpen(false)
                        resetGame(GameViewState.GAME_INITIALIZING)

                        if (
                            mData.data.state === 'InProgress' ||
                            mData.data.state === 'DrawIsOffered' ||
                            mData.data.state === 'AbortIsOffered'
                        ) {
                            initializeGameOnReconnect({
                                gameData: mData.data,
                                timeStamp: mData.timeStamp,
                                userID: userID!,
                            })
                        } else {
                            initialiseGame({ gameData: gd, userID, timeStamp: mData.timeStamp })
                        }
                        setChallenges([] as any)
                        setDrawerOpen('loading')

                        if (mData.data.matchKind === 'human_vs_human') {
                            getScore({
                                challengerId: mData.data.player1.playerId,
                                challengeeId: mData.data.player2.playerId,
                            })
                        }

                        analyticsManager.dispatchEvent('gameStarted', {
                            matchKind: gd.matchKind,
                            minutes: gd.timeMode.durationMinutes,
                            increment: gd.timeMode.clockIncrementSeconds,
                            rated: gd.rated,
                            difficulty: gd.difficulty?.level,
                            challengeString: gd.timeMode.durationMinutes + '-' + gd.timeMode.clockIncrementSeconds,
                        })

                        if (mData.data.state === 'DrawIsOffered') {
                            setDrawStatus(StateChangeRequest.RECEIVED)
                        }
                        if (mData.data.state === 'AbortIsOffered') {
                            setAbortStatus(StateChangeRequest.RECEIVED)
                        }

                        // ----------------------------------------------------------- move response (opening?)
                    } else if (mData.requestType === GS_REQUESTS.MOVE && mData.data) {
                        if (mData.data.opening && mData.correlationId) {
                            addMoveOpening({ moveID: mData.correlationId, opening: mData.data.opening })
                        }

                        receiveMoveRequestAcknowledgement({ clock: mData.data.clock })
                        // DEV-2412
                        // setTimeout(updateDeferredGameTree, 200)
                        updateDeferredGameTree()
                        // ----------------------------------------------------------- draw offer received
                    } else if (mData.messageType === GS_MESSAGES.DRAW_OFFER) {
                        setDrawStatus(StateChangeRequest.RECEIVED)
                        // ----------------------------------------------------------- draw offer response
                    } else if (mData.requestType === GS_REQUESTS.DRAW_OFFER && mData.result) {
                        if (mData.result.status === 'success') {
                            setDrawStatus(StateChangeRequest.REQUESTED)
                        }
                    }
                    // ----------------------------------------------------------- abort offer received
                    else if (mData.messageType === GS_MESSAGES.ABORT_OFFER) {
                        setAbortStatus(StateChangeRequest.RECEIVED)
                        // ----------------------------------------------------------- abort offer response
                    } else if (mData.requestType === GS_REQUESTS.ABORT_OFFER && mData.result) {
                        if (mData.result.status === 'success') {
                            setAbortStatus(StateChangeRequest.REQUESTED)
                        }
                        // ----------------------------------------------------------- draw offer rejected
                    } else if (mData.requestType === GS_REQUESTS.DRAW_REJECTED && mData.result) {
                        if (mData.result.status === 'success') {
                            //
                        }
                        // ----------------------------------------------------------- abort offer rejected
                    } else if (mData.requestType === GS_REQUESTS.ABORT_REJECTED && mData.result) {
                        if (mData.result.status === 'success') {
                            //
                        }
                    }
                    // ----------------------------------------------------------- draw cancelled
                    else if (mData.messageType === GS_MESSAGES.DRAW_OFFER_REMOVED) {
                        setDrawStatus(StateChangeRequest.REMOVED)
                    }
                    // ----------------------------------------------------------- abort cancelled
                    else if (mData.messageType === GS_MESSAGES.ABORT_OFFER_REMOVED) {
                        setAbortStatus(StateChangeRequest.REMOVED)
                    } else if (mData.messageType === GS_MESSAGES.CONNECT_ON_ANOTHER_DEVICE) {
                        shutdownGSConnection()
                        resetGame(GameViewState.PRE_GAME)
                        setOpponentDisconnected(undefined)
                        if (window.location.pathname === '/') {
                            setDrawerOpen('open')
                            setIsDrawerMenuOpen(true)
                        }
                        // @ts-ignore
                        serverConnectionStates.GSStatus.current = false
                    }
                })
            }
        },
        // TODO: evaluate if this is correct
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            addMoveOpening,
            authToken,
            connectionStatus,
            initialiseGame,
            matchData,
            receiveMove,
            sendJoin,
            setConnectionStatus,
            setDrawStatus,
            setDrawerOpen,
            setGameResult,
            setSocket,
            userID,
            setAbortStatus,
            receiveClockUpdate,
            userData?.user_name,
            gameStartedTimestamp,
            updateDeferredGameTree,
            setCanClaimVictory,
        ],
    )

    return (
        <Paper
            className={'serverConnection gs'}
            style={{
                display: serverConnectionData ? 'block' : 'none',
            }}
        >
            GS: {connectionStatus} {import.meta.env.VITE_REACT_APP_ENV}-{import.meta.env.VITE_REACT_APP_VERSION}{' '}
        </Paper>
    )
}

export default GameServerConnector
