import { Action, action } from 'easy-peasy'
import {
    addStrMoveToMainline,
    createRootGameTree,
    getLastPosition,
    getMoveById,
    getNextPosition,
    getPreviousPosition,
    getStartingPosition,
} from '../../chess/gameTree'
import { BasePositionFEN } from '../../chess/positionPresets'
import { Color, GameTree } from '../../chess/types'
import toPrecision from '../../functions/toPrecision'
import ReconnectingWebSocket from '../../socket/ReconnectingWebsocket'
import { ClockInstant, ConnectionStatus, GameResult, GameResultType, LiveGameData } from '../../store/types'
import { GameViewState } from '../gameView/GameViewModel'

// ----------------------------------------------------------------------------- type

export type ObserveViewConnectionModel = {
    socket: ReconnectingWebSocket | undefined
    connectionStatus: ConnectionStatus
    flipped: boolean
    gameTree: GameTree
    currentPositionId: string
    clockInstantWhite?: ClockInstant
    clockInstantBlack?: ClockInstant
    gameState: GameViewState
    gameResult: GameResult | undefined
    clockRunningFor: Color | undefined
    liveGameData: LiveGameData | undefined
    activeTab: number
    connectedToGame: boolean
    opening: string | undefined
    gameStartTimestamp: number | undefined
    sessionResultHistory: {
        whitePlayerId: string
        blackPlayerId: string
        winnerId: string | undefined
        type: GameResultType
    }[]

    setClockInstantWhite: Action<ObserveViewConnectionModel, ClockInstant | undefined>
    setClockInstantBlack: Action<ObserveViewConnectionModel, ClockInstant | undefined>
    setGameResult: Action<ObserveViewConnectionModel, GameResult>

    receiveMove: Action<
        ObserveViewConnectionModel,
        {
            lastMove: string
            clockFace: {
                blackLeft: number
                whiteLeft: number
                timeStamp: number
                runningFor: 'black' | 'white'
            }
        }
    >

    setGameTree: Action<ObserveViewConnectionModel, GameTree>
    setCurrentPositionId: Action<ObserveViewConnectionModel, string>
    setFlipped: Action<ObserveViewConnectionModel, boolean>
    initialiseGame: Action<
        ObserveViewConnectionModel,
        {
            gameTree: GameTree
            clockFace: {
                blackLeft: number
                whiteLeft: number
                timeStamp: number
                runningFor: 'black' | 'white'
            }
        }
    >
    setActiveTab: Action<ObserveViewConnectionModel, number>
    setOpening: Action<ObserveViewConnectionModel, string | undefined>
    setSocket: Action<ObserveViewConnectionModel, ReconnectingWebSocket | undefined>
    setConnectionStatus: Action<ObserveViewConnectionModel, ConnectionStatus>

    sendSubscribe: Action<ObserveViewConnectionModel, { matchId: string; liveGameData: LiveGameData }>
    resubscribe: Action<ObserveViewConnectionModel>
    setToPreviousPosition: Action<ObserveViewConnectionModel>
    setToNextPosition: Action<ObserveViewConnectionModel>
    resetObserverState: Action<ObserveViewConnectionModel>
}

// ----------------------------------------------------------------------------- initial state

const initialGameTree = createRootGameTree(BasePositionFEN)

const observeViewConnectionModel: ObserveViewConnectionModel = {
    socket: undefined,
    connectionStatus: ConnectionStatus.NOT_INITIALIZED,
    flipped: false,
    gameTree: initialGameTree,
    currentPositionId: getStartingPosition(initialGameTree).id,
    clockRunningFor: undefined,
    gameState: GameViewState.PRE_GAME,
    gameResult: undefined,
    liveGameData: undefined,
    activeTab: 0,
    connectedToGame: false,
    opening: undefined,
    gameStartTimestamp: undefined,
    sessionResultHistory: [],

    // ----------------------------------------------------------------------------- simple actions

    setSocket: action((state, socket) => {
        state.socket = socket
    }),

    setConnectionStatus: action((state, status) => {
        state.connectionStatus = status
    }),

    setGameTree: action((state, payload) => {
        state.gameTree = payload
        state.currentPositionId = getStartingPosition(payload).id
    }),

    setFlipped: action((state, flipped) => {
        state.flipped = flipped
    }),

    setCurrentPositionId: action((state, payload) => {
        state.currentPositionId = payload
    }),

    setClockInstantWhite: action((state, clockInstant) => {
        state.clockInstantWhite = clockInstant
    }),

    setClockInstantBlack: action((state, clockInstant) => {
        state.clockInstantBlack = clockInstant
    }),
    setGameResult: action((state, gameResult) => {
        state.gameResult = gameResult
        state.gameState = GameViewState.GAME_OVER
        state.clockRunningFor = undefined

        if (!!gameResult.clock) {
            const now = new Date().getTime()
            state.clockInstantBlack = {
                secondsRemaining: gameResult.clock.blackLeft < 0 ? 0 : gameResult.clock.blackLeft,
                when: state.clockInstantBlack?.when || now,
            }
            state.clockInstantWhite = {
                secondsRemaining: gameResult.clock.whiteLeft < 0 ? 0 : gameResult.clock.whiteLeft,
                when: state.clockInstantWhite?.when || now,
            }
        }

        if (!state.liveGameData) return

        const { player1, player2 } = state.liveGameData

        const [whitePlayer, blackPlayer] = player1.playerColor === 'white' ? [player1, player2] : [player2, player1]
        const whitePlayerId = whitePlayer.playerId
        const blackPlayerId = blackPlayer.playerId

        const winnerId =
            gameResult.winner === Color.White
                ? whitePlayerId
                : gameResult.winner === Color.Black
                    ? blackPlayerId
                    : undefined
        const type = gameResult.type

        const newResult = { whitePlayerId, blackPlayerId, winnerId, type }

        const prevResult = state.sessionResultHistory[state.sessionResultHistory.length - 1]

        if (
            prevResult &&
            ((prevResult.whitePlayerId === newResult.whitePlayerId &&
                prevResult.blackPlayerId === newResult.blackPlayerId) ||
                (prevResult.whitePlayerId === newResult.blackPlayerId &&
                    prevResult.blackPlayerId === newResult.whitePlayerId))
        ) {
            state.sessionResultHistory.push(newResult)
        } else {
            state.sessionResultHistory = [newResult]
        }
        state.connectedToGame = false
    }),
    setActiveTab: action((state, activeTab) => {
        state.activeTab = activeTab
    }),

    setOpening: action((state, opening) => {
        state.opening = opening
    }),

    // ----------------------------------------------------------------------------- socket actions

    initialiseGame: action((state, config) => {
        const now = new Date().getTime()

        const { gameTree, clockFace } = config
        if (gameTree.moves.length === 0) state.gameStartTimestamp = clockFace.timeStamp
        else {
            const lastMoveId = getLastPosition(gameTree).previousMoveId
            const lastMove = lastMoveId ? getMoveById(gameTree, lastMoveId) : undefined
            if (lastMove) lastMove.timestamp = clockFace.timeStamp
        }

        state.gameTree = gameTree
        state.currentPositionId = getLastPosition(gameTree).id
        state.gameState = GameViewState.GAME_RUNNING
        state.connectedToGame = true
        state.clockInstantBlack = {
            secondsRemaining: clockFace.blackLeft,
            when: clockFace.timeStamp || now,
        }
        state.clockInstantWhite = {
            secondsRemaining: clockFace.whiteLeft,
            when: clockFace.timeStamp || now,
        }

        state.clockRunningFor = clockFace.runningFor === 'black' ? Color.Black : Color.White
        state.flipped = false
    }),

    sendSubscribe: action((state, config) => {
        if (state.socket === undefined) return
        if (state.connectionStatus === ConnectionStatus.NOT_INITIALIZED) state.socket.reconnect()
        if (state.liveGameData?.matchId) {
            const msg = JSON.stringify({
                requestType: 'unsubscribeFromMatch',
                rawData: { matchId: state.liveGameData.matchId },
            })
            state.socket.send(msg)
        }
        const msg = JSON.stringify({
            requestType: 'subscribeToMatch',
            rawData: { matchId: config.matchId },
        })
        state.liveGameData = config.liveGameData
        state.gameResult = undefined
        state.socket.send(msg)
    }),

    resubscribe: action((state) => {
        if (state.socket === undefined) return
        if (state.gameState !== GameViewState.GAME_OVER) return
        if (state.connectionStatus === ConnectionStatus.NOT_INITIALIZED) state.socket.reconnect()
        if (state.liveGameData?.matchId) {
            const msg = JSON.stringify({
                requestType: 'subscribeToMatch',
                rawData: { matchId: state.liveGameData.matchId },
            })
            state.socket.send(msg)
        }
    }),

    receiveMove: action((state, config) => {
        const now = new Date().getTime()
        const prevMoveId = getLastPosition(state.gameTree).previousMoveId
        const prevMove = prevMoveId ? getMoveById(state.gameTree, prevMoveId!) : undefined
        const lastMoveTs = prevMove ? prevMove.timestamp : state.gameStartTimestamp

        const moveTime = lastMoveTs ? toPrecision((config.clockFace.timeStamp - lastMoveTs) / 1000, 1) : undefined
        const opponentClock = toPrecision(config.clockFace.runningFor === 'black' ? config.clockFace.whiteLeft : config.clockFace.blackLeft, 1)

        const newPos = addStrMoveToMainline(
            state.gameTree,
            config.lastMove,
            undefined,
            undefined,
            opponentClock,
            moveTime,
            config.clockFace.timeStamp,
        )
        state.clockInstantBlack = {
            secondsRemaining: config.clockFace.blackLeft,
            when: now,
        }
        state.clockInstantWhite = {
            secondsRemaining: config.clockFace.whiteLeft,
            when: now,
        }
        state.clockRunningFor = config.clockFace.runningFor === 'black' ? Color.Black : Color.White
        state.gameState = GameViewState.GAME_RUNNING
        state.currentPositionId = newPos.id
    }),

    setToPreviousPosition: action((state) => {
        const prevPosition = getPreviousPosition(state.gameTree, state.currentPositionId)
        if (prevPosition) state.currentPositionId = prevPosition.id
    }),

    setToNextPosition: action((state) => {
        const nextPosition = getNextPosition(state.gameTree, state.currentPositionId)
        if (nextPosition) state.currentPositionId = nextPosition.id
    }),

    resetObserverState: action((state) => {
        state.gameTree = initialGameTree
        state.currentPositionId = getStartingPosition(initialGameTree).id
        state.clockInstantBlack = undefined
        state.clockInstantWhite = undefined
        state.flipped = false
        state.gameState = GameViewState.PRE_GAME
        state.gameResult = undefined
        state.clockRunningFor = undefined
        state.liveGameData = undefined
        state.activeTab = 0
        state.opening = undefined
        state.connectedToGame = false
        state.connectionStatus = ConnectionStatus.NOT_INITIALIZED
    }),
}

export default observeViewConnectionModel
