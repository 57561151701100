import { Box } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { getLastPosition, getMoveById, getStartingPosition } from '../../../chess/gameTree'
import { Color, GameTreePosition, Move } from '../../../chess/types'
import { PageView, PlayerInfoBar } from '../../../components/PlayerInfoBar'
import { PlayerInfo } from '../../../functions/getPlayerInfos'
import { Layout } from '../../../sharedComponents/src/hooks/useResponsiveSizings'
import { useStoreActions, useStoreState } from '../../../store/hooks'
import { GameResult } from '../../../store/types'
import { GameViewState } from '../../gameView/GameViewModel'
import { Notation } from '../../notation/Notation'
import { NotationControls } from '../../notation/components/NotationControls'
import AnalysisClock from '../AnalysisClock'
import EngineSection from '../components/engineSection/EngineSection'
import { StockEngineResultType } from '../useStockfish/useStockFish'

export type Props = {
    matchTitle: string
    gameResult: GameResult | undefined
    editableAnnotations: boolean
    userData: any
    gtpos?: GameTreePosition
    headers?: [{ [index: string]: string }, number]
    isEngineLoaded: boolean
    startEngine: () => void
    stopEngine: () => void
    allVariations: StockEngineResultType
    addEngineMove: (move: Move | Move[]) => void
    layout: Layout
    isCalculating: boolean
}

const AnalysisTab = ({
    matchTitle,
    gameResult,
    editableAnnotations,
    gtpos,
    headers,
    isEngineLoaded,
    startEngine,
    stopEngine,
    allVariations,
    addEngineMove,
    isCalculating,
    layout,
}: Props) => {
    const PGNHeader = useStoreState((state) => state.PGNHeader)
    const flipped = useStoreState((state) => state.analysisMode.flipped)

    const colorToMove = useStoreState((state) => state.analysisMode.colorToMove)
    const gameTree = useStoreState((state) => state.analysisMode.gameTree)
    const currentPositionId = useStoreState((state) => state.analysisMode.currentPositionId)
    const setCurrentPositionId = useStoreActions((state) => state.analysisMode.setCurrentPositionId)
    const setToPreviousPosition = useStoreActions((state) => state.analysisMode.setToPreviousPosition)
    const setToNextPosition = useStoreActions((state) => state.analysisMode.setToNextPosition)

    const setMoveAnnotation = useStoreActions((state) => state.analysisMode.setMoveAnnotation)

    const [whitePlayer, setWhitePlayer] = useState<PlayerInfo>({ name: PGNHeader.White })
    const [blackPlayer, setBlackPlayer] = useState<PlayerInfo>({ name: PGNHeader.Black })

    const hasNextMoves = gtpos ? gtpos.nextMoveIds.length > 0 : false
    const hasPrevMoves = gtpos ? gtpos.previousMoveId !== undefined : false

    useEffect(() => {
        if (headers) {
            const {
                White,
                Black,
                WhiteTitle,
                BlackTitle,
                WhiteElo,
                BlackElo,
                BlackAvatarUrl,
                WhiteAvatarUrl,
                BlackCountry,
                WhiteCountry,
                TimeMode,
            } = headers[0]

            setWhitePlayer({
                name: White,
                rating: Math.round(Number(WhiteElo)),
                title: WhiteTitle !== 'undefined' ? WhiteTitle : '',
                ratingType: TimeMode || '',
                avatarUrl: WhiteAvatarUrl,
                nationality: WhiteCountry,
            })

            setBlackPlayer({
                name: Black,
                rating: Math.round(Number(BlackElo)),
                title: BlackTitle !== 'undefined' ? BlackTitle : '',
                ratingType: TimeMode || '',
                avatarUrl: BlackAvatarUrl,
                nationality: BlackCountry,
            })
        }
    }, [headers])

    const onMoveSelected = (id: string) => {
        const m = getMoveById(gameTree, id)
        if (m) setCurrentPositionId(m.nextPositionId)
    }

    const onFirst = useCallback(() => {
        setCurrentPositionId(getStartingPosition(gameTree).id)
    }, [gameTree, setCurrentPositionId])

    const onLast = useCallback(() => {
        setCurrentPositionId(getLastPosition(gameTree).id)
    }, [gameTree, setCurrentPositionId])

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'ArrowLeft') {
                event.preventDefault()
                setToPreviousPosition()
            }
            if (event.key === 'ArrowRight') {
                event.preventDefault()
                setToNextPosition()
            }
            if (event.key === 'ArrowUp') {
                event.preventDefault()
                onFirst()
            }
            if (event.key === 'ArrowDown') {
                event.preventDefault()
                onLast()
            }
        }
        document.addEventListener('keydown', handleKeyDown)
        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    }, [gameTree, onFirst, onLast, setToPreviousPosition, setToNextPosition])

    return (
        <>
            {layout === Layout.DESKTOP && (
                <PlayerInfoBar
                    player={flipped ? whitePlayer : blackPlayer}
                    clock={
                        <AnalysisClock
                            gameTree={gameTree}
                            currentPositionId={currentPositionId}
                            playerColor={flipped ? Color.White : Color.Black}
                        />
                    }
                    color={flipped ? Color.White : Color.Black}
                    gameState={GameViewState.GAME_OVER}
                    gtpos={gtpos}
                    top={true}
                    view={PageView.ANALYSIS}
                    showRating={true}
                />
            )}

            <Notation
                matchTitle={matchTitle}
                gameTree={gameTree}
                currentPositionId={currentPositionId}
                gameResult={gameResult}
                editableAnnotations={editableAnnotations}
                onAnnotationChange={(moveId, annotation) => {
                    setMoveAnnotation({ moveID: moveId, annotation })
                }}
                analysisHeaders={headers}
                analysis={true}
                sx={{ order: { xs: 3, md: 'unset' } }}
                isTable={false}
            >
                <EngineSection
                    isCalculating={isCalculating}
                    isEngineLoaded={isEngineLoaded}
                    startEngine={startEngine}
                    stopEngine={stopEngine}
                    allVariations={allVariations}
                    addEngineMove={addEngineMove}
                    colorToMove={colorToMove}
                />
            </Notation>
            <Box order={{ xs: 2, md: 'unset' }}>
                <NotationControls
                    hasNextMoves={hasNextMoves}
                    hasPrevMoves={hasPrevMoves}
                    onFirstMove={onFirst}
                    onPrevMove={setToPreviousPosition}
                    onNextMove={setToNextPosition}
                    onLastMove={onLast}
                />
            </Box>

            {layout === Layout.DESKTOP && (
                <PlayerInfoBar
                    player={flipped ? blackPlayer : whitePlayer}
                    clock={
                        <AnalysisClock
                            gameTree={gameTree}
                            currentPositionId={currentPositionId}
                            playerColor={flipped ? Color.Black : Color.White}
                        />
                    }
                    color={flipped ? Color.Black : Color.White}
                    gameState={GameViewState.GAME_OVER}
                    gtpos={gtpos}
                    top={false}
                    view={PageView.ANALYSIS}
                    showRating={true}
                />
            )}
        </>
    )
}

export default AnalysisTab
