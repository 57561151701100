import Stack from '@mui/material/Stack'
import { useEffect, useState } from 'react'
import { AreaType } from '../GlobalHeader'
import AnalysisIcon from '../icons/analysis'
import BurgerIcon from '../icons/burger'
import HomeIcon from '../icons/home'
import LiveGamesIcon from '../icons/live_games'
import MembershipsIcon from '../icons/memberships'
import NewsIcon from '../icons/news'
import PlayIcon from '../icons/play'
import PuzzleIcon from '../icons/puzzle'
import ShopIcon from '../icons/shop'
import VideosIcon from '../icons/videos'
import { IAppConfig, getAppConfig } from '../services/AppConfigService'
import Dropdown, { DropdownMenuItem } from './Dropdown'

export type BurgerNavigationProps = {
    websiteURL: string
    playAreaURL: string
    forumsURL: string
    learningURL: string
    shopURL: string
    area: AreaType
    Link: any
}

const BurgerNavigation = (props: BurgerNavigationProps) => {
    const { websiteURL, playAreaURL, forumsURL, learningURL, shopURL, area, Link } = props
    const [appConfig, setAppConfig] = useState<IAppConfig | null>(null)

    useEffect(() => {
        const config = getAppConfig()
        setAppConfig(config)
    }, [])

    let burgerDropdownItems: DropdownMenuItem[] = [
        {
            link: `${websiteURL}/`,
            title: 'Home',
            icon: <HomeIcon />,
        },
        {
            link: `${playAreaURL}/`,
            title: 'Play now',
            // target: '_blank',
            icon: <PlayIcon />,
        },
        // TODO: Uncomment when learning is ready
        // {
        //     id: 2,
        //     link: learningURL,
        //     title: 'Learn',
        //     icon: '',
        // },
        {
            link: `${playAreaURL}/watch`,
            title: 'Watch Games',
            icon: <LiveGamesIcon />,
        },
        {
            link: `${playAreaURL}/analysis`,
            title: 'Analysis',
            icon: <AnalysisIcon />,
        },
        // {
        //     link: `${playAreaURL}/puzzles`,
        //     title: 'Puzzles Quest',
        //     icon: '',
        // },
        {
            link: `${playAreaURL}/training`,
            title: 'Puzzles',
            icon: <PuzzleIcon />,
        },
        {
            link: `${websiteURL}/videos`,
            title: 'Videos',
            icon: <VideosIcon />,
        },
        {
            link: `${websiteURL}/article/archive`,
            title: 'News',
            icon: <NewsIcon />,
        },
        {
            link: `${shopURL}`,
            title: 'Shop',
            icon: <ShopIcon />,
        },
        // TODO: Uncomment when forums are ready
        // {
        //     link: forumsURL,
        //     title: 'Forums',
        //     icon: '',
        // },
        ...(!!appConfig?.hideMemberships
            ? []
            : [
                  {
                      link: `${websiteURL}/memberships`,
                      title: 'Memberships',
                      icon: <MembershipsIcon />,
                  },
              ]),
    ]

    return (
        <Stack
            className="burger-menu-wrapper"
            direction="row"
            sx={{
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Dropdown
                Link={Link}
                area={area}
                menuButtonTitle={
                    <Stack
                        direction="row"
                        sx={{
                            py: '10px',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <BurgerIcon />
                    </Stack>
                }
                dropdownItems={burgerDropdownItems}
            />
        </Stack>
    )
}
export default BurgerNavigation
