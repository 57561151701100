import { getMoveById, getNextPosition, getPositionById, getPreviousPosition } from '../../chess/gameTree'
import { Color, GameTree, GameTreePosition } from '../../chess/types'
import { Clock } from '../gameView/components/clock/Clock'

type Props = {
    currentPositionId?: string
    gameTree?: GameTree
    playerColor?: Color
    colorToMove?: Color
}

const AnalysisClock: React.FC<Props> = ({ currentPositionId, gameTree, playerColor }) => {
    if (!currentPositionId || !gameTree || !playerColor) return null

    const currentGTPosition = getPositionById(gameTree, currentPositionId)

    let lastClock: number | undefined = undefined
    let indexPosition: GameTreePosition | undefined = currentGTPosition
    if (!indexPosition.previousMoveId) {
        const posAfterFirstMove = getNextPosition(gameTree, indexPosition.id)
        const posAfterSecondMove = posAfterFirstMove ? getNextPosition(gameTree, posAfterFirstMove.id) : undefined

        indexPosition = posAfterSecondMove
    } else if (!getPreviousPosition(gameTree, indexPosition.id)?.previousMoveId) {
        const posAfterSecondMove = getNextPosition(gameTree, indexPosition.id)
        indexPosition = posAfterSecondMove
    }

    while (indexPosition?.previousMoveId) {
        const move = getMoveById(gameTree, indexPosition.previousMoveId)
        if (indexPosition.position.turn !== playerColor && move && move.clock) {
            lastClock = move.clock
            break
        }
        indexPosition = getPreviousPosition(gameTree, indexPosition.id)
    }

    if (!lastClock) return null

    return <Clock running={false} instant={{ secondsRemaining: lastClock, when: Date.now() }} />
}

export default AnalysisClock
