import { Button, Stack } from '@mui/material'
import { InfoDialogTemplate, InfoDialogText } from '../../InfoDialogTemplate/InfoDialogTemplate'
import SettingsSwitch from '../../settings/SettingsSwitch'

interface WelcomeUserFormProps {
    username: string
    hideRating: boolean
    onHideRatingChange: (value: boolean) => void
    onClose: () => void
}

const WelcomeUserForm: React.FC<WelcomeUserFormProps> = ({ onClose, username, hideRating, onHideRatingChange }) => {
    const onHideRatingHandler = () => {
        onHideRatingChange(!hideRating)
    }

    return (
        <InfoDialogTemplate
            open={true}
            handleClose={onClose}
            content={
                <>
                    <InfoDialogText
                        header={<> Welcome {username}</>}
                        subheader="your account is set to Casual Play"
                        description={
                            <>
                                Enjoy the game and get better with every move without the focus on ratings!
                                <br />
                                To display ratings, simply toggle the switch below or access your settings at anytime.
                            </>
                        }
                    />
                    <Stack sx={{ mt: { xs: 2, md: 6 } }} alignItems="center">
                        <SettingsSwitch
                            label="Casual Play"
                            checked={hideRating}
                            helpText="Hide Ratings"
                            onChange={onHideRatingHandler}
                            // disabled={!settingsEnabled}
                            // onDisabledClick={openJoinUsForm}
                        />
                    </Stack>
                </>
            }
            actions={
                <Button variant="outlined" onClick={onClose}>
                    Close
                </Button>
            }
        />
    )
}

export default WelcomeUserForm
