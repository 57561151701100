import { IMAGES_CDN_LINK } from '../../utils/links'

export enum PiecesTheme {
    'DEFAULT' = 'default',
    'BASIC' = 'basic',
    'BLITZIN' = 'blitzin',
    'CHERRY' = 'cherry',
    'CITRUS' = 'citrus',
    'CLASSIC' = 'classic',
    'DASHER' = 'dasher',
    'ICE' = 'ice',
    'MARBLE' = 'marble',
    'TOON' = 'toon',
    'WOODEN' = 'wooden',
}

interface IPieceProps {
    isDragging: boolean
    squareWidth: number | string
    props?: any
}

export const piecesProvider = (providedTheme: PiecesTheme) => {
    const theme = Object.values(PiecesTheme).includes(providedTheme) ? providedTheme : PiecesTheme.DEFAULT
    return {
        wP: ({ isDragging, squareWidth, props = {} }: IPieceProps) => (
            <img
                {...props}
                src={`${IMAGES_CDN_LINK}/static/pieces/${theme}/wP.svg`}
                alt="wP"
                width={squareWidth}
                height={squareWidth}
            />
        ),
        wR: ({ isDragging, squareWidth, props = {} }: IPieceProps) => (
            <img
                {...props}
                src={`${IMAGES_CDN_LINK}/static/pieces/${theme}/wR.svg`}
                alt="wR"
                width={squareWidth}
                height={squareWidth}
            />
        ),
        wN: ({ isDragging, squareWidth, props = {} }: IPieceProps) => (
            <img
                {...props}
                src={`${IMAGES_CDN_LINK}/static/pieces/${theme}/wN.svg`}
                alt="wN"
                width={squareWidth}
                height={squareWidth}
            />
        ),
        wB: ({ isDragging, squareWidth, props = {} }: IPieceProps) => (
            <img
                {...props}
                src={`${IMAGES_CDN_LINK}/static/pieces/${theme}/wB.svg`}
                alt="wB"
                width={squareWidth}
                height={squareWidth}
            />
        ),
        wQ: ({ isDragging, squareWidth, props = {} }: IPieceProps) => (
            <img
                {...props}
                src={`${IMAGES_CDN_LINK}/static/pieces/${theme}/wQ.svg`}
                alt="wQ"
                width={squareWidth}
                height={squareWidth}
            />
        ),
        wK: ({ isDragging, squareWidth, props = {} }: IPieceProps) => (
            <img
                {...props}
                src={`${IMAGES_CDN_LINK}/static/pieces/${theme}/wK.svg`}
                alt="wK"
                width={squareWidth}
                height={squareWidth}
            />
        ),
        bP: ({ isDragging, squareWidth, props = {} }: IPieceProps) => (
            <img
                {...props}
                src={`${IMAGES_CDN_LINK}/static/pieces/${theme}/bP.svg`}
                alt="bP"
                width={squareWidth}
                height={squareWidth}
            />
        ),
        bR: ({ isDragging, squareWidth, props = {} }: IPieceProps) => (
            <img
                {...props}
                src={`${IMAGES_CDN_LINK}/static/pieces/${theme}/bR.svg`}
                alt="bR"
                width={squareWidth}
                height={squareWidth}
            />
        ),
        bN: ({ isDragging, squareWidth, props = {} }: IPieceProps) => (
            <img
                {...props}
                src={`${IMAGES_CDN_LINK}/static/pieces/${theme}/bN.svg`}
                alt="bN"
                width={squareWidth}
                height={squareWidth}
            />
        ),
        bB: ({ isDragging, squareWidth, props = {} }: IPieceProps) => (
            <img
                {...props}
                src={`${IMAGES_CDN_LINK}/static/pieces/${theme}/bB.svg`}
                alt="bB"
                width={squareWidth}
                height={squareWidth}
            />
        ),
        bQ: ({ isDragging, squareWidth, props = {} }: IPieceProps) => (
            <img
                {...props}
                src={`${IMAGES_CDN_LINK}/static/pieces/${theme}/bQ.svg`}
                alt="bQ"
                width={squareWidth}
                height={squareWidth}
            />
        ),
        bK: ({ isDragging, squareWidth, props = {} }: IPieceProps) => (
            <img
                {...props}
                src={`${IMAGES_CDN_LINK}/static/pieces/${theme}/bK.svg`}
                alt="bK"
                width={squareWidth}
                height={squareWidth}
            />
        ),
    }
}
