export type OpenSignupEvent = {
    origin: 'header' | 'welcomeScreen' | 'loginDialog' | 'guestWelcome' | 'guestProfileDropdown'
}

export type OpenLoginEvent = {
    origin: 'header' | 'welcomeScreen' | 'signupDialog' | 'guestWelcome' | 'guestProfileDropdown'
}

export type CreateGuestAccountEvent = {}

export type CloseGuestWelcomeEvent = {}

export type SignupReachSecondPageEvent = {}

export type CompleteSignupEvent = {
    country: string
    isOver12: boolean
    authType: 'email/user_name' | 'google' | 'apple' | 'facebook'
}

export type RequestResendEvent = {}

export type ConfirmEmailEvent = {}

export type CompleteLoginEvent = { authType: 'email/user_name' | 'google' | 'apple' | 'facebook' }

export type LogoutEvent = {}

export type CustomChallengeEvent = {
    minutes: number // this or challengeString?
    increment: number
    rated: boolean
    color: 'white' | 'black' | 'noColor'
    ratingFrom: number
    ratingTo: number
}

export type ComputerChallengeEvent = {
    minutes: number
    increment: number
    difficulty: number
    color: 'white' | 'black' | 'noColor'
    gameType: 'custom' | 'standard'
}

export type EnterAutoPoolEvent = { challengeString: string }

export type ExitAutoPoolEvent = { challengeString: string }

export type TimeInQueueEvent = {
    challengeType: 'custom' | 'autoMatch' | 'direct'
    rated: boolean
    challengeString: string
    timeInQueue: number
}

export type GameStartedEvent = {
    challengeString?: string
    matchKind: 'human_vs_human' | 'human_vs_ai'
    minutes: number
    increment: number
    rated: boolean
    difficulty?: number
}

export type ResignEvent = {}

export type OfferDrawEvent = {}

export type OfferAbortEvent = {}

export type OfferRematchEvent = {}

export type AcceptRematchEvent = {}

export type GameFinishedEvent = {
    timeInGame: number
    reason: String
    method: string
}

export type ClickVideoEvent = {
    videoUrl: string
}

export type NextPuzzleEvent = {
    datePlayed: string
    puzzleId: string
    puzzleRating: number
    userRating: number
    result: string
    attempts: number
    usedHints: number
}

export type AnalyticsEventMap = {
    openSignup: OpenSignupEvent
    openLogin: OpenLoginEvent
    createGuestAccount: CreateGuestAccountEvent
    closeGuestWelcome: CloseGuestWelcomeEvent
    signupReachSecondPage: SignupReachSecondPageEvent
    completeSignup: CompleteSignupEvent
    requestResend: RequestResendEvent
    confirmEmail: ConfirmEmailEvent
    completeLogin: CompleteLoginEvent
    logout: LogoutEvent
    customChallenge: CustomChallengeEvent
    computerChallenge: ComputerChallengeEvent
    enterAutoPool: EnterAutoPoolEvent
    exitAutoPool: ExitAutoPoolEvent
    timeInQueue: TimeInQueueEvent
    gameStarted: GameStartedEvent
    resign: ResignEvent
    offerDraw: OfferDrawEvent
    offerAbort: OfferAbortEvent
    offerRematch: OfferRematchEvent
    acceptRematch: AcceptRematchEvent
    gameFinished: GameFinishedEvent
    clickVideo: ClickVideoEvent
    nextPuzzle: NextPuzzleEvent
}

export type EventListenerFunction<K extends keyof AnalyticsEventMap> = (ev: AnalyticsEventMap[K]) => any

export type EventListeners = {
    [key in keyof AnalyticsEventMap]?: EventListenerFunction<key>[]
}

export interface AnalyticsManagerInterface {
    addEventListener<K extends keyof AnalyticsEventMap>(type: K, listener: EventListenerFunction<K>): void

    removeEventListener<K extends keyof AnalyticsEventMap>(type: K, listener: EventListenerFunction<K>): void

    dispatchEvent<K extends keyof AnalyticsEventMap>(type: K, ev: AnalyticsEventMap[K]): void
}

export class AnalyticsManager implements AnalyticsManagerInterface {
    private eventListeners: EventListeners

    constructor() {
        this.eventListeners = {}
    }

    addEventListener<K extends keyof AnalyticsEventMap>(type: K, listener: EventListenerFunction<K>): void {
        if (!this.eventListeners[type]) {
            this.eventListeners[type] = []
        }

        this.eventListeners[type]!.push(listener)
    }

    removeEventListener<K extends keyof AnalyticsEventMap>(type: K, listener: EventListenerFunction<K>): void {
        const listeners = this.eventListeners[type]
        if (!listeners) {
            return
        }

        this.eventListeners[type] = listeners.filter((l) => l !== listener) as typeof listeners
    }

    dispatchEvent<K extends keyof AnalyticsEventMap>(type: K, ev: AnalyticsEventMap[K]): void {
        this.eventListeners[type]?.forEach((listener) => {
            try {
                listener(ev)
            } catch (e) {
                console.error(`Error occurred in listener for event type '${type}':`, e)
            }
        })
    }
}

export const analyticsManager = new AnalyticsManager()
