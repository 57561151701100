import { Box, Stack } from '@mui/material'
import { forwardRef, useEffect, useRef, useState } from 'react'

import { Color } from '../../chess/types'
import { GameViewState, StateChangeRequest } from '../../features/gameView/GameViewModel'
import ConfirmationDialogs, {
    ConfirmationType,
} from '../../features/gameView/components/confirmationDialogs/ConfirmationDialogs'
import MatchMakingDrawer from '../../features/matchMakingDrawer/MatchMakingDrawer'
import { LoginState } from '../../sharedComponents/src/globalHeader/GlobalHeader'
import { analyticsManager } from '../../sharedComponents/src/globalHeader/services/analytics/AnalyticsManager'
import { Layout } from '../../sharedComponents/src/hooks/useResponsiveSizings'
import { useStoreActions, useStoreState } from '../../store/hooks'
import { FTUE } from '../FTUE/FTUE'
import { LoginMenu } from '../LoginMenu'
import { ConnectionFailedOverlay } from '../overlays/ConnectionFailedOverlay/ConnectionFailedOverlay'
import { OpponentDisconnectedSnackbar } from './StyledSnackbar/OpponentDisconnectedSnackbar'
import StyledSnackbar from './StyledSnackbar/StyledSnackbar'

type Props = {
    layout: Layout
    aboveBoard?: JSX.Element
    board: JSX.Element
    belowBoard?: JSX.Element
    children?: React.ReactNode
    dataProvider?: JSX.Element
}

export const sizings = {
    header: '80px',
    margin: '32px',
    rightPanelMin: '356px',
    rightPanelMax: '450px',
    gap: '16px',
}

const opponentColor = {
    [Color.White]: 'b',
    [Color.Black]: 'w',
}

const AppLayout = forwardRef<HTMLDivElement, Props>(
    ({ layout, aboveBoard, board, belowBoard, dataProvider, children }: Props, ref) => {
        const userData = useStoreState((state) => state.userData)
        const boardContainerRef = useRef<HTMLDivElement>(null)
        const myColor = useStoreState((state) => state.gameView.myColor)
        const rematchStatus = useStoreState((state) => state.matchMaker.rematchStatus)
        const rematchMatchData = useStoreState((state) => state.matchMaker.rematchMatchData)
        const snackbarMessage = useStoreState((state) => state.snackbarMessage)
        const drawerOpen = useStoreState((state) => state.drawerOpen)
        const gameState = useStoreState((state) => state.gameView.gameState)
        const connectionStatus = useStoreState((state) => state.matchMaker.connectionStatus)

        const setGameEndEnabled = useStoreActions((state) => state.gameView.setGameEndEnabled)
        const acceptChallenge = useStoreActions((state) => state.matchMaker.acceptChallenge)
        const sendRejectChallenge = useStoreActions((state) => state.matchMaker.sendRejectChallenge)
        const setRematchStatus = useStoreActions((state) => state.matchMaker.setRematchStatus)
        const setSnackbarMessage = useStoreActions((state) => state.setSnackbarMessage)

        const [dialog, setDialog] = useState<ConfirmationType | undefined>()
        const [snackBarOpen, setSnackBarOpen] = useState(false)

        // disable scroll when touching the board on mobile
        useEffect(() => {
            const preventScroll = (event) => {
                event.preventDefault()
            }
            if (boardContainerRef.current) {
                boardContainerRef.current?.addEventListener('touchmove', preventScroll), { passive: 'false' }
                // DESKTOP
                // boardContainerRef.current?.addEventListener('wheel', preventScroll), { passive: 'false' }
            }
            return () => {
                if (boardContainerRef.current) {
                    boardContainerRef.current?.removeEventListener('touchmove', preventScroll)
                    // DESKTOP
                    // boardContainerRef.current?.removeEventListener('wheel', preventScroll)
                }
            }
        }, [boardContainerRef.current])

        // if a rematch offer has come in, open the dialog
        useEffect(() => {
            if (rematchStatus === StateChangeRequest.RECEIVED && gameState !== GameViewState.GAME_RUNNING)
                setDialog(ConfirmationType.ACCEPT_REMATCH)
        }, [rematchStatus])

        useEffect(() => {
            if (snackbarMessage !== undefined) {
                setSnackBarOpen(true)
            }
        }, [snackbarMessage])

        //  confirm dialog confirm handling
        const handleConfirm = (type: ConfirmationType) => {
            if (type === ConfirmationType.ACCEPT_REMATCH) {
                setRematchStatus(undefined)
                if (rematchMatchData) {
                    acceptChallenge({ id: rematchMatchData.pairId })
                    setGameEndEnabled(false)
                    analyticsManager.dispatchEvent('acceptRematch', {})
                }
            }
            setDialog(undefined)
        }

        //  confirm dialog cancel handling
        const handleCancel = (type: ConfirmationType) => {
            if (type === ConfirmationType.ACCEPT_REMATCH) {
                setRematchStatus(undefined)
                if (rematchMatchData) {
                    sendRejectChallenge({ pairId: rematchMatchData.pairId, isRematch: true })
                }
            }
            setDialog(undefined)
        }

        const handleSnackBarOpenClose = () => {
            setSnackBarOpen(false)
            setRematchStatus(undefined)
            setSnackbarMessage(undefined)
        }

        const isPlayZone = window.location.pathname === '/'

        const hideBoard =
            layout === Layout.MOBILE &&
            (userData.state === LoginState.NOT_LOGGED_IN || drawerOpen === 'open' || drawerOpen === 'loading')

        return (
            <>
                {dataProvider}
                <Box ref={ref}>
                    <Stack
                        direction={layout === Layout.MOBILE ? 'column' : 'row'}
                        alignItems="stretch"
                        justifyContent="center"
                        mx={{ xs: `calc(${sizings.margin}/4)`, md: sizings.margin }}
                        mb={{ xs: `calc(${sizings.margin}/4)`, md: sizings.margin }}
                        gap={sizings.gap}
                        maxHeight={{ xs: 'unset', md: `calc(100vh - ${sizings.margin} - ${sizings.header})` }}
                        // maxHeight={{
                        //     xs: 'unset',
                        //     md: `min(100vh - ${sizings.margin} - ${sizings.header}, 100vw - ${sizings.gap} - ${sizings.rightPanelMin} - ${sizings.margin}*2)`,
                        // }}
                        margin="0 auto"
                    >
                        {!hideBoard && (
                            <Box display="flex" flexDirection="column" alignItems="center" gap="8px">
                                {aboveBoard}
                                <Box
                                    position="relative"
                                    // maxHeight="1024px"
                                    // maxWidth="1024px"
                                    ref={boardContainerRef}
                                    height={{
                                        xs: `min(100dvh - ${sizings.margin} - ${sizings.header}, 100vw - ${sizings.margin}/2)`,
                                        md: `min(100vh - ${sizings.margin} - ${sizings.header}, 100vw - ${sizings.gap} - ${sizings.rightPanelMin} - ${sizings.margin}*2)`,
                                    }}
                                    width={{
                                        xs: `min(100dvh - ${sizings.margin} - ${sizings.header}, 100vw - ${sizings.margin}/2)`,
                                        md: `min(100vh - ${sizings.margin} - ${sizings.header}, 100vw - ${sizings.gap} - ${sizings.rightPanelMin} - ${sizings.margin}*2)`,
                                    }}
                                    sx={(theme) => ({
                                        display: 'flex',
                                        padding: '5px',
                                        userSelect: 'none',
                                        // overflow: 'hidden',
                                        border: `2px solid ${theme.palette.primary.light} !important`,
                                        // ENEMY CHESS PIECES ALWAYS VISIBLE
                                        [`div[color="figure-${opponentColor[myColor]}"]`]: {
                                            opacity: isPlayZone ? '1 !important' : 'unset',
                                        },
                                    })}
                                >
                                    {board}
                                </Box>
                                {belowBoard}
                            </Box>
                        )}
                        <Box
                            flexGrow={1}
                            width="100%"
                            overflow="auto"
                            minWidth={{ xs: '100%', md: sizings.rightPanelMin }}
                            maxWidth={{ xs: '100%', md: sizings.rightPanelMax }}
                        >
                            <Box
                                display="flex"
                                alignItems="stretch"
                                flexDirection="column"
                                flexGrow={1}
                                justifyContent="flex-start"
                                height={{
                                    xs: hideBoard
                                        ? `calc(100dvh - ${sizings.margin}/2  - ${sizings.header})`
                                        : 'max(700px, 80dvh)',
                                    md: '100%',
                                }}
                                gap="12px"
                            >
                                {userData.state === LoginState.NOT_LOGGED_IN ? (
                                    <LoginMenu dialog={false} />
                                ) : (
                                    <>
                                        <MatchMakingDrawer />
                                        {!hideBoard && drawerOpen !== 'open' && drawerOpen !== 'loading' && children}
                                    </>
                                )}
                            </Box>
                        </Box>
                    </Stack>
                </Box>
                {dialog && (
                    <ConfirmationDialogs
                        type={dialog}
                        onClose={handleCancel}
                        onConfirm={handleConfirm}
                        rematchUser={rematchMatchData?.userName ? rematchMatchData.userName : 'Opponent'}
                    />
                )}
                <StyledSnackbar isOpen={snackBarOpen} message={snackbarMessage} onClose={handleSnackBarOpenClose} />
                <OpponentDisconnectedSnackbar />
                <ConnectionFailedOverlay connectionStatus={connectionStatus} />
                <FTUE />
            </>
        )
    },
)

export default AppLayout
