import { Box, Button, Checkbox, FormControlLabel, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { CustomTimeSelector } from '../../components/CustomTimeSelector'
import { ColorSelection, ColorSelector } from '../../components/colorSelector/ColorSelector'
import { NewChallengeSelector } from '../../components/newChallengeSelector/NewChallengeSelector'
import { UserSearchResult } from '../../sharedComponents/src/globalHeader/functions/ams-functions'
import { useStoreActions, useStoreState } from '../../store/hooks'
import { MMChallengeType } from '../../store/types'

export type DirectChallengeDrawerProps = {
    onClose: () => void
    user: UserSearchResult | undefined
    setActiveMainTab: (tab: number) => void
}

const dividerStyles = {
    marginTop: '1rem',
    marginBottom: '1rem',
    borderBottomWidth: 2,
    backgroundColor: '#999999',
}

function DirectChallengeDrawer(props: DirectChallengeDrawerProps) {
    const { user, onClose } = props

    const [color, setColor] = useState<ColorSelection>(ColorSelection.RANDOM)
    const [selectedChallenge, setSelectedChallenge] = useState<string>('3,0,blitz')
    const [rated, setRated] = useState<boolean>(true)
    const [isCustomChallenge, setIsCustomChallenge] = useState<boolean>(false)
    const [playDisabled, setPlayDisabled] = useState<boolean>(true)

    const settings = useStoreState((state) => state.gameView.settings)

    const setActiveTab = useStoreActions((state) => state.setActiveTab)
    const sendPlayerChallenge = useStoreActions((state) => state.matchMaker.sendPlayerChallenge)

    const [time, setTime] = useState<number>(5)
    const [increment, setIncrement] = useState<number>(0)

    useEffect(() => {
        if (time > 0 && increment >= 0) {
            setPlayDisabled(false)
        } else {
            setPlayDisabled(true)
        }
    }, [time, increment])

    const onCreateChallenge = () => {
        sendPlayerChallenge({
            rated,
            color,
            time: time,
            increment: increment,
            challengeeId: user?.id,
            challengeType: MMChallengeType.DIRECT,
        })
        onClose()
    }

    useEffect(() => {
        if (selectedChallenge) {
            setTime(Number(selectedChallenge.split(',')[0]))
            setIncrement(Number(selectedChallenge.split(',')[1]))
        }
    }, [selectedChallenge])

    const challengeButtons = [
        '1,0,bullet',
        '3,0,blitz',
        '3,2,blitz',
        '5,0,blitz',
        '5,3,blitz',
        '10,0,rapid',
        '15,10,rapid',
        '30,0,rapid',
    ]

    return (
        <>
            <Box display="flex">
                <Typography sx={{ whiteSpace: 'nowrap', marginRight: 1 }} variant={'h4'}>
                    VS
                </Typography>
                <Typography color="secondary.main" noWrap textTransform="none" variant={'h4'}>
                    {user?.userName}
                </Typography>
            </Box>
            <Box
                p={0}
                style={{
                    gap: '16px',
                    display: 'flex',
                    flexDirection: 'column',
                    flex: '1 1 auto',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                }}
            >
                {!isCustomChallenge && (
                    <NewChallengeSelector
                        headerTitle="Time control"
                        launchedChallenges={selectedChallenge}
                        onSelected={(challenge) => {
                            setSelectedChallenge(challenge)
                        }}
                        vsHuman={false}
                        buttons={challengeButtons}
                        handleCustomButton={() => setIsCustomChallenge(true)}
                    />
                )}
                {isCustomChallenge && (
                    <Box>
                        <CustomTimeSelector
                            time={time}
                            increment={increment}
                            setTime={setTime}
                            setIncrement={setIncrement}
                        />
                        <br />
                        <Button variant="outlined" fullWidth onClick={() => setIsCustomChallenge(false)}>
                            Select from list
                        </Button>
                    </Box>
                )}

                {!settings.friendlyMode && (
                    <FormControlLabel
                        sx={{ margin: 0, justifyContent: 'space-between' }}
                        control={<Checkbox color="info" />}
                        label="Rated Game"
                        labelPlacement="start"
                        onChange={(e: any) => setRated(e.target.checked)}
                        checked={rated}
                    />
                )}

                <ColorSelector
                    value={color}
                    onChange={(color) => {
                        setColor(color)
                    }}
                />
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    height: '3.125rem',
                    backgroundColor: '#101820',
                    outline: '1.25rem solid #101820',
                    flex: '0 1 3.125rem',
                }}
                gap={1}
            >
                <Button variant="outlined" fullWidth onClick={onClose}>
                    CANCEL
                </Button>
                <Button
                    variant="contained"
                    fullWidth
                    onClick={() => {
                        onCreateChallenge()
                        onClose()
                        setActiveTab(0)
                    }}
                    disabled={playDisabled}
                >
                    CREATE
                </Button>
            </Box>
        </>
    )
}

export default DirectChallengeDrawer
