import CloseIcon from '@mui/icons-material/Close'
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    FormControl,
    FormControlLabel,
    FormHelperText,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    Link,
    ListSubheader,
    MenuItem,
    Select,
    Stack,
    Typography,
} from '@mui/material'
import debounce from 'lodash-es/debounce'
import throttle from 'lodash-es/throttle'
import { useCallback, useEffect, useState } from 'react'
import SocialLogin from '../../SocialLogin/SocialLogin'
import { countries } from '../common/countries'
import { isEmailTaken, validateUsername } from '../functions/ams-functions'
import { IAppConfig, getAppConfig } from '../services/AppConfigService'
import { ValidationState } from './types'

const throttledUsernameCheck = throttle(validateUsername, 500)
const throttledEmailCheck = throttle(isEmailTaken, 500)

// was to optimistic to start refactoring, maybe one day...
export const emailsValidationTexts: Partial<Record<ValidationState, { text: string; color: string }>> = {
    [ValidationState.LOADING]: { text: 'Checking...', color: 'black' },
    [ValidationState.FREE]: { text: 'Email valid', color: 'green' },
    [ValidationState.TAKEN]: { text: 'Email already exists', color: 'red' },
    [ValidationState.WAITING]: { text: 'Checking...', color: 'black' },
}

const inputStyle = {
    margin: '0 0 16px 0',
    borderColor: 'rgb(16 24 32)',
    '.MuiFormLabel-root': {
        color: '#101820',
        fontSize: '12px',
        fontFamily: 'Space Grotesk',
    },
    '.MuiInputBase-input': {
        borderBottom: '1px solid rgb(16 24 32)',
        color: 'rgb(16 24 32)',
    },
    '.MuiTypography-body1': {
        color: '#101820',
        fontSize: '12px',
        fontFamily: 'Space Grotesk',
    },
    '.MuiTypography-body2': {
        color: 'red',
        fontSize: '12px',
        fontFamily: 'Space Grotesk',
    },
    '.MuiInput-underline:after': {
        borderBottom: '1px solid rgb(16 24 32)',
    },
    '.MuiInputLabel-root.Mui-focused': {
        color: 'rgb(16, 24, 32)',
    },
}

const buttonStyle = {
    padding: '10px 57px',
    fontFamily: 'Fira Sans',
    backgroundColor: '#FFFFFF',
    color: '#000000',
    fontSize: '21px',
    border: '2px solid #000000',
    width: '182px',
    height: '48px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '0',
    '&.MuiButton-text': {
        paddingTop: '15px',
    },
    '&:hover': {
        backgroundColor: '#101820',
        color: '#FFFFFF',
    },

    '&:disabled': {
        color: '#C3C3C3',
        border: '2px solid #C3C3C3',
    },
}

const checkBox = {
    '.MuiTypography-body1': {
        color: 'rgb(16 24 32)',
        fontSize: '12px',
        fontFamily: 'Space Grotesk',
    },
    '.MuiSvgIcon-root': {
        fill: 'rgb(16 24 32)',
    },
    '.MuiButtonBase-root': {
        padding: '4px 4px 4px 10px ',
    },
    marginRight: '0',
}

const stackStyle = {
    padding: '16px',
    backgroundColor: '#fff',
    minWidth: '338px',
    maxWidth: '338px',
    overflowY: 'auto',
    maxHeight: '80vh',
    '& .createAccountStack': {
        padding: '16px',
    },
    border: '2px solid #101820',
} as const

export interface SignUpFormProps {
    onClose: () => void
    onSignin: () => void
    onLoginSocialSuccessful: () => void
    onSignUpSocial: (socialResponse: any) => void
    apiURL: string
    websiteURL: string
    onSubmit: (email: string, password: string, user_name: string, country: string, over12: boolean) => void
    guestUsername?: string
    logoutHandler: () => Promise<void>
    googleClientId?: string
    facebookAppId?: string
    facebookApiVersion?: string
    appleClientId?: string
    appleRedirectURL?: string
    env: string
}

function SignUpForm(props: SignUpFormProps) {
    const {
        onClose,
        onSignin,
        apiURL,
        websiteURL,
        onSubmit,
        guestUsername,
        onSignUpSocial,
        onLoginSocialSuccessful,
        logoutHandler,
        googleClientId,
        facebookAppId,
        facebookApiVersion,
        appleClientId,
        appleRedirectURL,
        env,
    } = props
    const [isFirstPageValid, setIsFirstPageValid] = useState(false)
    const [isSecondPageValid, setIsSecondPageValid] = useState(false)
    const [email, setEmail] = useState('')
    const [isEmailValid, setIsEmailValid] = useState<boolean | undefined>(undefined)
    const [isUsernameValid, setIsUsernameValid] = useState<boolean | undefined>(undefined)
    const [isPasswordValid, setIsPasswordValid] = useState<boolean | undefined>(undefined)
    const [doPasswordsMatch, setDoPasswordsMatch] = useState<boolean | undefined>(undefined)
    const [acceptTerms, setAcceptTerms] = useState(false)
    const [password, setPassword] = useState('')
    const [confirmationPassword, setConfirmationPassword] = useState('')
    const [username, setUsername] = useState('')
    const [country, setCountry] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmationPassword, setShowConfirmationPassword] = useState(false)
    const [usernameCheckState, setUsernameCheckState] = useState(ValidationState.WAITING)
    const [emailCheckState, setEmailCheckState] = useState(ValidationState.WAITING)
    const [firstPage, setFirstPage] = useState(true)
    const [over12, setOver12] = useState(true)
    const [appConfig, setAppConfig] = useState<IAppConfig | null>(null)

    useEffect(() => {
        const config = getAppConfig()
        setAppConfig(config)
    }, [])

    //check if first page is valid
    useEffect(() => {
        if (
            isEmailValid &&
            isPasswordValid &&
            doPasswordsMatch &&
            acceptTerms &&
            emailCheckState === ValidationState.FREE
        ) {
            setIsFirstPageValid(true)
        } else {
            setIsFirstPageValid(false)
        }
    }, [isEmailValid, isPasswordValid, doPasswordsMatch, username, country, acceptTerms, emailCheckState])

    useEffect(() => {
        if (isUsernameValid && (usernameCheckState === ValidationState.FREE || username === guestUsername)) {
            setIsSecondPageValid(true)
        } else {
            setIsSecondPageValid(false)
        }
    }, [isUsernameValid, country, usernameCheckState])

    // ---------------------------------------------------------------- Email validation -----------------

    const emailCheckCallback = (result: boolean) => {
        setEmailCheckState(result ? ValidationState.TAKEN : ValidationState.FREE)
    }

    const emailChangeHandler = async (email: string) => {
        const current = email
        setEmail(current)
        const regex =
            env === 'prod'
                ? /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
                : /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
        if (current.length < 3 || !regex.test(current)) {
            setIsEmailValid(false)
            return
        }
        setEmailCheckState(ValidationState.LOADING)
        setIsEmailValid(true)
        await throttledEmailCheck(current, apiURL, emailCheckCallback)
    }

    // -------------------------------------------------------------- Password validation -----------------

    const onPasswordChange = (password: string) => {
        setIsPasswordValid(password.length > 7)
    }

    const debouncedPasswordChange = useCallback(debounce(onPasswordChange, 500), [])

    useEffect(() => {
        if (password === '') return

        const timer = setTimeout(() => {
            debouncedPasswordChange(password)
        }, 500)

        return () => {
            clearTimeout(timer)
        }
    }, [password, debouncedPasswordChange])

    // -------------------------------------------------------------- Confirm password validation -----------------

    const onConfirmationPasswordChange = (confirmationPassword: string, password: string) => {
        setDoPasswordsMatch(confirmationPassword === password)
    }

    const debouncedConfirmationPasswordChange = useCallback(debounce(onConfirmationPasswordChange, 500), [])

    useEffect(() => {
        if (confirmationPassword === '') return

        const timer = setTimeout(() => {
            debouncedConfirmationPasswordChange(confirmationPassword, password)
        }, 500)

        return () => {
            clearTimeout(timer)
        }
        //I left password out of dependencies to avoid running when not intended
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmationPassword, debouncedConfirmationPasswordChange])

    const [tooShort, setTooShort] = useState(false)
    const [tooLong, setTooLong] = useState(false)
    const [invalidChars, setInvalidChars] = useState(false)
    const [containsTitle, setContainsTitle] = useState(false)

    const nameChangeHandler = async (current: string) => {
        setUsername(current)
        if (current.length < 5) {
            setIsUsernameValid(false)
            setTooShort(true)
            return
        }
        if (current.length > 15) {
            setIsUsernameValid(false)
            setTooLong(true)
            return
        }

        if (!/^[a-zA-Z0-9]+$/.test(current)) {
            setIsUsernameValid(false)
            setInvalidChars(true)
            return
        }
        const titles = ['GM', 'IM', 'FM', 'WIM', 'WGM', 'CM', 'NM', 'WFM', 'WCM']
        const titleRegex = new RegExp(`^(${titles.join('|')})`)
        const usernameRegex = new RegExp(`(${titles.join('|')})$`)

        if (titleRegex.test(current) || usernameRegex.test(current)) {
            setIsUsernameValid(false)
            setContainsTitle(true)
            return
        }
        if (current === guestUsername) {
            setIsUsernameValid(true)
            return
        }
        setContainsTitle(false)
        setTooShort(false)
        setTooLong(false)
        setInvalidChars(false)
        setUsernameCheckState(ValidationState.LOADING)
        setIsUsernameValid(true)
        await throttledUsernameCheck(current, apiURL, setUsernameCheckState)
    }

    // -------------------------------------------------------------- Form submission -----------------

    const onFirstPageSubmit = () => {
        if (isFirstPageValid) {
            setFirstPage(false)
        }
    }

    const onSecondPageSubmit = () => {
        if (isSecondPageValid) {
            onSubmit(email, password, username, country, over12)
        }
    }

    return (
        <Dialog onClose={onClose} open={true} sx={{ zIndex: '1600' }} PaperProps={{ sx: { m: 0 } }}>
            {firstPage && (
                <>
                    <Stack sx={stackStyle}>
                        <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <IconButton sx={{ color: 'black' }} onClick={onClose}>
                                <CloseIcon fontSize="large" />
                            </IconButton>
                        </Box>

                        <Stack className="createAccountStack">
                            <Typography
                                variant="h2"
                                style={{
                                    color: 'rgb(16 24 32)',
                                    textAlign: 'center',
                                    whiteSpace: 'nowrap',
                                    marginBottom: '20px',
                                    lineHeight: '100%',
                                }}
                            >
                                CREATE YOUR
                                <br />
                                FREE ACCOUNT
                            </Typography>

                            <FormControl sx={inputStyle} variant="standard">
                                <InputLabel htmlFor="email">Enter your email</InputLabel>
                                <Input
                                    id="email"
                                    sx={inputStyle}
                                    type="email"
                                    style={{ marginBottom: 0 }}
                                    value={email}
                                    onChange={(e) => emailChangeHandler(e.currentTarget.value)}
                                    endAdornment={
                                        <InputAdornment
                                            position="end"
                                            sx={{
                                                pointerEvents: 'none',
                                            }}
                                        >
                                            <IconButton aria-label="" edge="end" tabIndex={-1}>
                                                <img src="/assets/images/icons/user.svg" alt="email" />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    error={isEmailValid !== undefined && !isEmailValid}
                                />
                                {emailCheckState && isEmailValid && (
                                    <FormHelperText id="confirm-password-helper-text">
                                        <Typography style={{ color: emailsValidationTexts[emailCheckState]?.color }}>
                                            {emailsValidationTexts[emailCheckState]?.text}
                                        </Typography>
                                    </FormHelperText>
                                )}
                                {!isEmailValid && isEmailValid !== undefined && (
                                    <FormHelperText
                                        id="confirm-password-helper-text"
                                        style={{ color: 'red', marginTop: '4px' }}
                                    >
                                        <Typography variant="body2">E-mail not valid</Typography>
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <FormControl sx={inputStyle} variant="standard">
                                <InputLabel htmlFor="password">Enter your password</InputLabel>
                                <Input
                                    id="password"
                                    sx={inputStyle}
                                    type={showPassword ? 'text' : 'password'}
                                    style={{ marginBottom: 0 }}
                                    value={password}
                                    onChange={(e) => setPassword(e.currentTarget.value)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword(!showPassword)}
                                                edge="end"
                                                tabIndex={-1}
                                            >
                                                {showPassword ? (
                                                    <img
                                                        src="/assets/images/icons/pass-hidden.svg"
                                                        alt="hide password"
                                                    />
                                                ) : (
                                                    <img src="/assets/images/icons/pass-show.svg" alt="show password" />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    error={!isPasswordValid && isPasswordValid !== undefined}
                                />

                                {!isPasswordValid && isPasswordValid !== undefined && (
                                    <FormHelperText id="password-helper-text" style={{ color: 'red' }}>
                                        <Typography variant="body2">
                                            Password must be at least 8 characters long
                                        </Typography>
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <FormControl sx={inputStyle} variant="standard">
                                <InputLabel htmlFor="password">Confirm your password</InputLabel>
                                <Input
                                    id="confirmationPassword"
                                    sx={inputStyle}
                                    type={showConfirmationPassword ? 'text' : 'password'}
                                    style={{ marginBottom: 0 }}
                                    value={confirmationPassword}
                                    onChange={(e) => setConfirmationPassword(e.currentTarget.value)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowConfirmationPassword(!showConfirmationPassword)}
                                                edge="end"
                                                tabIndex={-1}
                                            >
                                                {showConfirmationPassword ? (
                                                    <img
                                                        src="/assets/images/icons/pass-hidden.svg"
                                                        alt="hide password"
                                                    />
                                                ) : (
                                                    <img src="/assets/images/icons/pass-show.svg" alt="show password" />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    error={!doPasswordsMatch && doPasswordsMatch !== undefined}
                                />
                                {!doPasswordsMatch && doPasswordsMatch !== undefined && (
                                    <FormHelperText
                                        id="confirm-password-helper-text"
                                        style={{ color: 'red', marginTop: '4px' }}
                                    >
                                        <Typography variant="body2">Passwords do not match</Typography>
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <Box
                                style={{
                                    padding: '16px 0',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Box>
                                    <FormControlLabel
                                        sx={checkBox}
                                        value="end"
                                        onChange={() => setAcceptTerms(!acceptTerms)}
                                        control={<Checkbox size="small" />}
                                        label="Accept the"
                                        labelPlacement="end"
                                        checked={acceptTerms}
                                    />{' '}
                                    <Link
                                        href={`${websiteURL}/eula`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        sx={{
                                            paddingLeft: '0px',
                                            color: 'rgb(16 24 32)',
                                            fontSize: '12px',
                                            textDecoration: 'underline',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        Terms and Conditions
                                    </Link>
                                </Box>
                            </Box>
                            <Box style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button sx={buttonStyle} disabled={!isFirstPageValid} onClick={onFirstPageSubmit}>
                                    Sign UP
                                </Button>
                            </Box>
                            {!(appConfig && appConfig.hideSSO) && (
                                <SocialLogin
                                    title="Or Sign up with:"
                                    onSignUpSocial={onSignUpSocial}
                                    onLoginSuccessful={onLoginSocialSuccessful}
                                    logoutHandler={logoutHandler}
                                    isGuest={guestUsername !== undefined}
                                    apiURL={apiURL}
                                    googleClientId={googleClientId}
                                    facebookAppId={facebookAppId}
                                    facebookApiVersion={facebookApiVersion}
                                    appleClientId={appleClientId}
                                    appleRedirectURL={appleRedirectURL}
                                />
                            )}
                            <Box
                                sx={{
                                    color: 'rgb(16 24 32)',
                                    fontSize: '14px',
                                    textAlign: 'center',
                                    marginTop: '20px',
                                    fontFamily: 'Space Grotesk',
                                }}
                            >
                                I already have an account
                                <Link
                                    sx={{
                                        paddingLeft: '10px',
                                        color: 'rgb(16 24 32)',
                                        fontSize: '14px',
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        onSignin()
                                    }}
                                >
                                    Log in
                                </Link>
                            </Box>
                        </Stack>
                    </Stack>
                </>
            )}
            {!firstPage && (
                <>
                    <Stack style={stackStyle}>
                        <Stack direction={'row'} style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box
                                sx={{
                                    color: '#666979',
                                    fontSize: '20px',
                                    textAlign: 'center',
                                }}
                            >
                                <Link
                                    sx={{
                                        display: 'flex',
                                        paddingLeft: '10px',
                                        color: '#666979',
                                        fontSize: '20px',
                                        textDecoration: 'none',
                                        cursor: 'pointer',

                                        fontFamily: 'Space Grotesk',
                                    }}
                                    onClick={() => {
                                        setFirstPage(true)
                                    }}
                                >
                                    <img
                                        src="/assets/images/icons/back.svg"
                                        alt="back"
                                        style={{ paddingRight: '5px' }}
                                    />
                                    Back
                                </Link>
                            </Box>
                            <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <IconButton sx={{ color: 'black' }} onClick={onClose}>
                                    <CloseIcon fontSize="large" />
                                </IconButton>
                            </Box>
                        </Stack>

                        <Stack style={{ padding: 16 }}>
                            <Typography
                                variant="h2"
                                sx={{
                                    color: 'rgb(16 24 32)',
                                    textAlign: 'center',
                                    pt: '30px',
                                    pb: '40px',
                                }}
                            >
                                ALMOST DONE!
                            </Typography>

                            <FormControl sx={inputStyle} variant="standard">
                                <InputLabel htmlFor="username">Pick a username</InputLabel>
                                <Input
                                    id="username"
                                    sx={inputStyle}
                                    type="username"
                                    value={username}
                                    style={{ marginBottom: 0 }}
                                    onChange={(e) => nameChangeHandler(e.target.value)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton aria-label="" edge="end" tabIndex={-1}>
                                                <img src="/assets/images/icons/edit.svg" alt="username" />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    error={!isUsernameValid && isUsernameValid !== undefined}
                                />
                                {usernameCheckState && !tooLong && !tooShort && !containsTitle && !invalidChars && (
                                    <FormHelperText id="confirm-password-helper-text">
                                        <Typography variant="body2">
                                            {usernameCheckState === 'loading' && (
                                                <Typography style={{ color: 'black' }}>Checking...</Typography>
                                            )}
                                            {usernameCheckState === 'free' && (
                                                <Typography style={{ color: 'green' }}>Username available</Typography>
                                            )}
                                            {usernameCheckState === 'taken' && (
                                                <Typography style={{ color: 'red' }}>Username not available</Typography>
                                            )}
                                        </Typography>
                                    </FormHelperText>
                                )}
                                {!isUsernameValid && isUsernameValid !== undefined && tooShort && (
                                    <FormHelperText
                                        id="confirm-password-helper-text"
                                        style={{ color: 'red', marginTop: '4px' }}
                                    >
                                        <Typography variant="body2">Username too short</Typography>
                                    </FormHelperText>
                                )}
                                {!isUsernameValid && isUsernameValid !== undefined && containsTitle && (
                                    <FormHelperText
                                        id="confirm-password-helper-text"
                                        style={{ color: 'red', marginTop: '4px' }}
                                    >
                                        <Typography variant="body2">Titles not permitted</Typography>
                                    </FormHelperText>
                                )}
                                {!isUsernameValid && isUsernameValid !== undefined && tooLong && (
                                    <FormHelperText
                                        id="confirm-password-helper-text"
                                        style={{ color: 'red', marginTop: '4px' }}
                                    >
                                        <Typography variant="body2">
                                            Username must be a maximum of 15 characters
                                        </Typography>
                                    </FormHelperText>
                                )}
                                {!isUsernameValid && isUsernameValid !== undefined && invalidChars && (
                                    <FormHelperText
                                        id="confirm-password-helper-text"
                                        style={{ color: 'red', marginTop: '4px' }}
                                    >
                                        <Typography variant="body2">
                                            Username can only contain letters and numbers
                                        </Typography>
                                    </FormHelperText>
                                )}
                                {usernameCheckState === 'not_allowed' && (
                                    <Typography style={{ color: 'red' }}>Username can not be used</Typography>
                                )}
                            </FormControl>
                            {guestUsername && (
                                <FormControlLabel
                                    sx={checkBox}
                                    value="end"
                                    style={{
                                        maxWidth: '240px',
                                        color: '#101820',
                                        fontSize: '15px',
                                        marginTop: '-15px',
                                        marginBottom: '20px',
                                    }}
                                    control={
                                        <Checkbox
                                            size="medium"
                                            checked={username === guestUsername}
                                            onChange={() => {
                                                if (username !== guestUsername) nameChangeHandler(guestUsername)
                                            }}
                                        />
                                    }
                                    labelPlacement="end"
                                    label="Keep my current username"
                                />
                            )}

                            <FormControl variant="standard" sx={inputStyle}>
                                <InputLabel style={{ color: 'black' }}>
                                    <Typography
                                        sx={{
                                            color: 'rgb(16 24 32)',
                                        }}
                                    >
                                        Country
                                    </Typography>
                                </InputLabel>
                                <Select
                                    sx={{ inputStyle }}
                                    value={country}
                                    onChange={(e) => setCountry(e.target.value)}
                                    MenuProps={{
                                        className: 'selectMenu',
                                        sx: {
                                            '&.selectMenu': {
                                                zIndex: 1600,
                                            },
                                            '&  .MuiPopover-paper': {
                                                position: 'relative',
                                                width: 'fit-content',
                                                overflow: 'auto',
                                                maxHeight: '30vh',
                                                backgroundColor: 'white',
                                                border: '1px solid rgba(0, 0, 0, 0.23)',
                                                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                                                borderRadius: '4px',
                                                color: 'rgb(16 24 32)',
                                                '& .MuiMenuItem-root:hover': {
                                                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                                    transition: '0.2s ease-in-out',
                                                },
                                            },
                                        },
                                    }}
                                >
                                    <MenuItem value="US">United States</MenuItem>
                                    <MenuItem value="CA">Canada</MenuItem>
                                    <MenuItem value="GB">United Kingdom</MenuItem>
                                    <ListSubheader sx={{ borderBottom: '1px solid gray' }} />
                                    {countries.map((country) => (
                                        <MenuItem key={country.code} value={country.code}>
                                            {country.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {/* <Box style={{ padding: '30px 0' }}>
                                <FormControlLabel
                                    sx={checkBox}
                                    value="end"
                                    control={<Checkbox size="medium" />}
                                    style={{
                                        maxWidth: '240px',
                                        color: '#101820',
                                        fontSize: '15px',
                                    }}
                                    label="I am over 12."
                                    labelPlacement="end"
                                    onChange={() => setOver12(!over12)}
                                />
                            </Box> */}
                            <Box sx={{ pt: '30px', display: 'flex', justifyContent: 'center' }}>
                                <Button sx={buttonStyle} disabled={!isSecondPageValid} onClick={onSecondPageSubmit}>
                                    Sign Up
                                </Button>
                            </Box>
                        </Stack>
                    </Stack>
                </>
            )}
        </Dialog>
    )
}

export default SignUpForm
