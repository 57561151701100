import { Paper } from '@mui/material'
import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import { getLastPosition } from '../../../chess/gameTree'
import { gameFromPGN } from '../../../chess/pgn'
import { Color } from '../../../chess/types'
import ReconnectingWebSocket, {
    CloseEvent,
    ErrorEvent,
    Options,
    WS_ERROR_EVENT_STATUS,
} from '../../../socket/ReconnectingWebsocket'
import { useStoreActions, useStoreState } from '../../../store/hooks'
import { ConnectionStatus, GameResult, GameResultType } from '../../../store/types'
import { GAME_END_REASONS } from '../../gameView/components/gameServerConnector/GSTypes'

function ObserverServerConnector() {
    const connectionStatus = useStoreState((state) => state.observeView.connectionStatus)
    const token = useStoreState((state) => state.token)
    const serverConnectionData = useStoreState((state) => state.serverConnectionData)
    const setConnectionStatus = useStoreActions((state) => state.observeView.setConnectionStatus)
    const setSocket = useStoreActions((state) => state.observeView.setSocket)
    const initialiseGame = useStoreActions((state) => state.observeView.initialiseGame)
    const receiveMove = useStoreActions((state) => state.observeView.receiveMove)
    const setGameResult = useStoreActions((state) => state.observeView.setGameResult)
    const setGameTree = useStoreActions((state) => state.observeView.setGameTree)
    const setCurrentPositionId = useStoreActions((state) => state.observeView.setCurrentPositionId)
    const setSnackbarMessage = useStoreActions((state) => state.setSnackbarMessage)
    const resetObserverState = useStoreActions((state) => state.observeView.resetObserverState)
    const resubscribe = useStoreActions((state) => state.observeView.resubscribe)

    useEffect(() => {
        if (!token) return

        const url = `${import.meta.env.VITE_REACT_APP_OB_WS_URL}/v1/ws/observe?token=${token}`
        const options: Options = {
            debug: true,
            debugName: 'OB',
            maxReconnectionDelay: 5000,
            minReconnectionDelay: 5000,
            maxRetries: 3,
        }
        let ws = new ReconnectingWebSocket(url, undefined, options)

        ws.addEventListener('close', (ce: CloseEvent) => {
            // setConnectionStatus(ConnectionStatus.NOT_INITIALIZED)
        })

        ws.addEventListener('open', () => {
            setConnectionStatus(ConnectionStatus.CONNECTED)
            setSocket(ws)
        })

        ws.addEventListener('reconnect', () => {
            console.log('observer ws reconnected after it broke')
            setConnectionStatus(ConnectionStatus.CONNECTED)
            setSocket(ws)
            resubscribe()
        })

        ws.addEventListener('error', (ev: ErrorEvent) => {
            Sentry.captureMessage(`Observer error: ${ev.message}`)

            switch (ev.message) {
                case WS_ERROR_EVENT_STATUS.TIMEOUT:
                    setConnectionStatus(ConnectionStatus.CONNECTING)
                    break
                case WS_ERROR_EVENT_STATUS.MAX_RETRIES:
                    setConnectionStatus(ConnectionStatus.RECONNECT_FAILED)
                    break
                default:
                    // setConnectionStatus(ConnectionStatus.CONNECTION_ERROR)
                    // resetObserverState()
                    break
            }
        })

        ws.addEventListener('message', (me) => {
            const mData = JSON.parse(me.data)

            if (mData.requestType === 'subscribeToMatch' && mData.result.status === 'error') {
                setSnackbarMessage('This game has already ended')
            }

            if (mData.requestType === 'subscribeToMatch' && mData.data) {
                if (!mData.data.clockFace) console.error('no clockFace in subscribeToMatch')
                if (mData.data.clockFace) {
                    const { pgn, clockFace } = mData.data
                    const gameTree = gameFromPGN(pgn || '')

                    initialiseGame({ gameTree, clockFace })
                }
            }

            if (mData.messageType === 'moveInfoWithClockFaceMessage' && mData.data) {
                if (!mData.data.lastMove) console.error('no lastMove in moveInfoWithClockFaceMessage')
                if (!mData.data.clockFace) console.error('no clockFace in moveInfoWithClockFaceMessage')
                if (mData.data.lastMove && mData.data.clockFace) receiveMove(mData.data)
            }
            if (mData.messageType === 'endOfGameMessage') {
                const d = mData.data
                const result: GameResult = {
                    winner: undefined,
                    type: GameResultType.Unknown,
                    newRating: undefined,
                    clock: d.clock,
                    outcome: d.outcome,
                }
                if (d.pgn) {
                    const newGameTree = gameFromPGN(d.pgn)
                    setGameTree(newGameTree)
                    const lastPos = getLastPosition(newGameTree)
                    setCurrentPositionId(lastPos.id)
                }
                if (d.ratings) result.newRating = d.ratings

                // check the result who won ("0-1" is a fixed notation in chess!)
                if (d.outcome === '1-0') result.winner = Color.White
                else if (d.outcome === '0-1') result.winner = Color.Black

                // decipher the reason
                switch (d.reason) {
                    case GAME_END_REASONS.CHECK_MATE:
                        result.type = GameResultType.Checkmated
                        break
                    case GAME_END_REASONS.RESIGNATION:
                        result.type = GameResultType.Resigned
                        break
                    case GAME_END_REASONS.INSUFFICIENT_MATERIAL:
                        result.type = GameResultType.InsufficientMaterial
                        break
                    case GAME_END_REASONS.STALE_MATE:
                        result.type = GameResultType.Stalemate
                        break
                    case GAME_END_REASONS.THREE_FOLD_REPETITION:
                        result.type = GameResultType.ThreefoldRepetition
                        break
                    case GAME_END_REASONS.FIVE_FOLD_REPETITION:
                        result.type = GameResultType.FivefoldRepetition
                        break
                    case GAME_END_REASONS.FIFTY_MOVE_RULE:
                        result.type = GameResultType.FiftyMoveRule
                        break
                    case GAME_END_REASONS.SEVENTY_FIVE_MOVE_RULE:
                        result.type = GameResultType.SeventyFiveMoveRule
                        break
                    case GAME_END_REASONS.DRAW:
                        result.type = GameResultType.Draw
                        break
                    case GAME_END_REASONS.ABORTED:
                        result.type = GameResultType.Aborted
                        break
                    case GAME_END_REASONS.SECOND_PLAYER_JOIN_TIMEOUT:
                        result.type = GameResultType.SecondPlayerTimeout
                        break
                    case GAME_END_REASONS.CLOCK_EXPIRATION:
                        result.type = GameResultType.ForfeitedOnTime
                        break
                    case GAME_END_REASONS.TECHNICAL_ERROR_AI:
                        result.type = GameResultType.TechnicalErrorAi
                        break
                    case GAME_END_REASONS.NO_PLAYER1_MOVE_SINCE_START:
                    case GAME_END_REASONS.NO_PLAYER2_MOVE_SINCE_START:
                        result.type = GameResultType.NoPlayerMoveSinceStart
                        break
                    case GAME_END_REASONS.ALL_PLAYERS_DISCONNECTED:
                        result.type = GameResultType.AllPlayersDisconnected
                        break
                    case GAME_END_REASONS.PLAYER1_DISCONNECTED:
                    case GAME_END_REASONS.PLAYER2_DISCONNECTED:
                        result.type = GameResultType.PlayerDisconnected
                        break
                    case GAME_END_REASONS.REASON_NORMAL:
                    default:
                        result.type = GameResultType.Unknown
                        break
                }

                if (d.ratings) result.newRating = d.ratings

                setGameResult(result)
            }
        })

        return () => {
            console.log('resetting observer state in cleanup')
            ws.close()
            resetObserverState()
        }
    }, [
        token,
        setSocket,
        setConnectionStatus,
        resetObserverState,
        setSnackbarMessage,
        initialiseGame,
        receiveMove,
        setGameTree,
        setCurrentPositionId,
        setGameResult,
    ])

    return (
        <Paper
            className={'serverConnection gs'}
            style={{
                display: serverConnectionData ? 'block' : 'none',
            }}
        >
            OB: {connectionStatus} {import.meta.env.VITE_REACT_APP_OB_WS_URL}
        </Paper>
    )
}

export default ObserverServerConnector
