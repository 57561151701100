import { Box, Button, Slider, TextField, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ServerConnectionStates } from '../../../App'
import { isValidFen } from '../../../chess/fen'
import { CustomTimeSelector } from '../../../components/CustomTimeSelector'
import { ColorSelector } from '../../../components/colorSelector/ColorSelector'
import { LoginHint } from '../../../components/logginhint/LoginHint'
import { analyticsManager } from '../../../sharedComponents/src/globalHeader/services/analytics/AnalyticsManager'
import { useStoreActions, useStoreState } from '../../../store/hooks'
import { GameViewState } from '../../gameView/GameViewModel'
import { NewChallengeSelector } from './../../../components/newChallengeSelector/NewChallengeSelector'

export type PlayComputerProps = {
    loggedIn: boolean
}

const marks = [
    {
        value: 1,
        label: '1',
        rating: '500-800',
    },
    {
        value: 2,
        label: '2',
        rating: '800-1100',
    },
    {
        value: 3,
        label: '3',
        rating: '1100-1400',
    },
    {
        value: 4,
        label: '4',
        rating: '1400-1700',
    },
    {
        value: 5,
        label: '5',
        rating: '1700-2000',
    },
    {
        value: 6,
        label: '6',
        rating: '2000-2300',
    },
    {
        value: 7,
        label: '7',
        rating: '2300-2600',
    },
    {
        value: 8,
        label: '8',
        rating: '2600-2900',
    },
    {
        value: 9,
        label: '9',
        rating: '2900-3200',
    },
    {
        value: 10,
        label: '10',
        rating: '3200-3500',
    },
]

function PlayComputerPanel(props: PlayComputerProps) {
    const { loggedIn } = props
    // ----------------------------------------------------------------- state
    const gSSocket = useStoreState((state) => state.gameView.socket)
    const connectionStatus = useStoreState((state) => state.matchMaker.connectionStatus)
    const playComputerFen = useStoreState((state) => state.analysisMode.playComputerFen)
    const searchOptions = useStoreState((state) => state.matchMaker.searchOptions.computerChallenge)
    const fullSearchOptions = useStoreState((state) => state.matchMaker.searchOptions)
    const settings = useStoreState((state) => state.gameView.settings)

    // ----------------------------------------------------------------- actions
    const setMatchData = useStoreActions((state) => state.gameView.setMatchData)
    const clearGSConnection = useStoreActions((state) => state.gameView.shutDownGSConnection)
    const sendAIChallenge = useStoreActions((state) => state.matchMaker.sendAIChallenge)
    const setDrawerOpen = useStoreActions((state) => state.setDrawerOpen)
    const setAnalysisFen = useStoreActions((state) => state.analysisMode.setFen)
    const setPlayComputerFen = useStoreActions((state) => state.analysisMode.setPlayComputerFen)
    const setSearchOptions = useStoreActions((state) => state.matchMaker.setSearchOptions)
    const setGameState = useStoreActions((state) => state.gameView.setGameState)
    const resetGame = useStoreActions((state) => state.gameView.resetGame)

    // TODO: we need to get a message from the server when a play computer challenge has been created and remove this.
    const serverConnectionStates = useContext(ServerConnectionStates)

    const [playDisabled, setPlayDisabled] = useState<boolean>(true)
    const [validFen, setValidFen] = useState<boolean>(true)

    const navigate = useNavigate()

    React.useEffect(() => {
        if (searchOptions.customTime && searchOptions.minutes > 0 && searchOptions.increment >= 0) {
            setPlayDisabled(false)
        } else if (searchOptions.selectedChallenge != null && searchOptions.customTime === false && validFen) {
            setPlayDisabled(false)
        } else {
            setPlayDisabled(true)
        }
    }, [
        connectionStatus,
        searchOptions.customTime,
        validFen,
        searchOptions.selectedChallenge,
        searchOptions.minutes,
        searchOptions.increment,
    ])

    const send = () => {
        resetGame(GameViewState.PRE_GAME)
        gSSocket?.close()
        clearGSConnection()
        gSSocket?.addEventListener('close', () => {
            setMatchData({
                matchId: '',
                gameServiceUrl: '',
            })
        })

        let customMinutes = searchOptions.minutes
        let selectedMinutes = searchOptions.selectedChallenge
            ? Number(searchOptions.selectedChallenge.split(',')[0])
            : null
        let customIncrement = searchOptions.increment
        let selectedIncrement = searchOptions.selectedChallenge
            ? Number(searchOptions.selectedChallenge.split(',')[1])
            : null

        sendAIChallenge({
            color: searchOptions.color,
            fen: playComputerFen,
            strength: searchOptions.strength,
            time: searchOptions.customTime ? customMinutes : selectedMinutes!,
            increment: searchOptions.customTime ? customIncrement : selectedIncrement!,
            navigate: navigate,
        })

        analyticsManager.dispatchEvent('computerChallenge', {
            minutes: searchOptions.customTime ? customMinutes : selectedMinutes!,
            increment: searchOptions.customTime ? customIncrement : selectedIncrement!,
            difficulty: searchOptions.strength,
            color: searchOptions.color === 'no_color' ? 'noColor' : searchOptions.color,
            gameType: playComputerFen !== '' ? 'custom' : 'standard',
        })
        setGameState(3)
        setDrawerOpen('closed')
        // TODO: we need to get a message from the server when a play computer challenge has been created and remove this.
        //@ts-ignore
        serverConnectionStates.GSStatus.current = false
        navigate('/')
    }

    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        setSearchOptions({
            ...fullSearchOptions,
            computerChallenge: {
                ...searchOptions,
                strength: newValue as number,
            },
        })
    }

    const onSelected = (selected: string) => {
        setSearchOptions({
            ...fullSearchOptions,
            computerChallenge: {
                ...searchOptions,
                selectedChallenge: selected,
            },
        })
    }

    const onFenChange = (fen: string) => {
        if (fen === '') {
            setValidFen(true)
            setPlayDisabled(false)
        } else if (isValidFen(fen)) {
            setValidFen(true)
            setPlayDisabled(false)
            setPlayComputerFen(fen)
        } else {
            setValidFen(false)
            setPlayDisabled(true)
        }
        setAnalysisFen(fen)
    }

    const challengeButtons = [
        '1,0,bullet',
        '3,0,blitz',
        '3,2,blitz',
        '5,0,blitz',
        '5,3,blitz',
        '10,0,rapid',
        '15,10,rapid',
        '30,0,rapid',
    ]

    const handleCustomButton = (event) => {
        event.preventDefault()
        setSearchOptions({
            ...fullSearchOptions,
            computerChallenge: {
                ...searchOptions,
                customTime: !searchOptions.customTime,
            },
        })
    }

    return (
        <>
            <Box
                style={{
                    gap: '16px',
                    display: 'flex',
                    flexDirection: 'column',
                    flex: '1',
                    overflowY: 'auto',
                }}
            >
                {!searchOptions.customTime && (
                    <NewChallengeSelector
                        headerTitle="Time control"
                        launchedChallenges={
                            searchOptions.selectedChallenge != null ? [searchOptions.selectedChallenge] : []
                        }
                        onSelected={onSelected}
                        vsHuman={false}
                        buttons={challengeButtons}
                        handleCustomButton={handleCustomButton as any}
                    />
                )}
                {searchOptions.customTime && (
                    <CustomTimeSelector
                        time={searchOptions.minutes}
                        increment={searchOptions.increment}
                        setTime={(time) => {
                            setSearchOptions({
                                ...fullSearchOptions,
                                computerChallenge: {
                                    ...searchOptions,
                                    minutes: Number(time),
                                },
                            })
                        }}
                        setIncrement={(increment) => {
                            setSearchOptions({
                                ...fullSearchOptions,
                                computerChallenge: {
                                    ...searchOptions,
                                    increment: Number(increment),
                                },
                            })
                        }}
                    />
                )}
                {searchOptions.customTime && (
                    <Button variant="outlined" fullWidth onClick={handleCustomButton}>
                        Select from list
                    </Button>
                )}

                <Box
                    pb={1}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <Typography sx={{ display: 'flex', width: '100%' }} variant="h6">
                        Playing strength:
                        {!settings.friendlyMode && (
                            <Typography variant="h6" ml={1} color="secondary">
                                {marks[searchOptions.strength - 1].rating}
                            </Typography>
                        )}
                    </Typography>
                    <Slider
                        aria-label="Playing Strength Slider"
                        // in case we will want to show above slider custom values:
                        // valueLabelFormat={(x) => {
                        //     return marks[x - 1].rating
                        // }}
                        value={searchOptions.strength}
                        step={1}
                        marks={marks}
                        min={1}
                        max={10}
                        valueLabelDisplay="off"
                        sx={{ width: '95%', color: 'text.primary' }}
                        onChange={handleSliderChange}
                    />
                </Box>

                <ColorSelector
                    value={searchOptions.color}
                    onChange={(color) => {
                        setSearchOptions({
                            ...fullSearchOptions,
                            computerChallenge: {
                                ...searchOptions,
                                color: color,
                            },
                        })
                    }}
                />

                <Box>
                    <Typography variant="h6" pb={1}>
                        Starting Position (Paste FEN)
                    </Typography>
                    <TextField
                        error={!validFen}
                        helperText={!validFen ? 'Invalid FEN' : ''}
                        placeholder="paste FEN here"
                        fullWidth
                        value={
                            playComputerFen === 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1'
                                ? ''
                                : playComputerFen
                        }
                        onChange={(e) => onFenChange(e.target.value)}
                        sx={{
                            '& .Mui-focused .MuiIconButton-root': { color: 'secondary.main' },
                        }}
                    />
                    {(playComputerFen !== '' || !validFen) && (
                        <Button
                            fullWidth
                            size="small"
                            variant="text"
                            color="primary"
                            onClick={() => {
                                setPlayComputerFen('')
                                setAnalysisFen('')
                                setValidFen(true)
                            }}
                        >
                            Reset Starting Position
                        </Button>
                    )}
                </Box>
                {!loggedIn && <LoginHint />}
            </Box>

            {loggedIn && (
                <Button sx={{ mt: '16px' }} variant={'contained'} fullWidth onClick={send} disabled={playDisabled}>
                    Play
                </Button>
            )}
        </>
    )
}

export default PlayComputerPanel
